import styled from "@emotion/styled"
import { useTranslations } from "next-intl"
import Link from "next/link"
import { Pagination } from "swiper"
import "swiper/css"
import "swiper/css/effect-coverflow"
import "swiper/css/effect-fade"
import "swiper/css/pagination"
import { Swiper, SwiperSlide } from "swiper/react"
import { FileIcon2, ImageContainer, Mobile, NewText } from "ui"
import { sharedColors } from "ui/theme/colors"
import { getUmbracoMediaUrl, getUmbracoPDFUrl } from "utils"
import { chosenProjectType } from "utils/gtm"
import { ApartmentUmbraco } from "utils/projects"
import ApartmentGallery from "./ApartmentGallery"
import ApartmentPlan from "./ApartmentPlan"
import ApartmentSpec from "./ApartmentSpec"

const BodyHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 16px;
  }
  .swiper-wrapper {
    margin-bottom: 2rem;
  }
  .swiper-pagination {
    bottom: 0;
    width: max-content;
    left: auto;
    right: auto;
    inset-inline-start: 0;
    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      cursor: pointer;
      padding: 0;
      background-color: transparent;
      border-radius: 200px;
      border: 1px solid #000000;
    }
    .swiper-pagination-bullet-active {
      background: black;
    }
  }
`
const StyledDocs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 24px;
  }
`

const Document = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 72px;
  cursor: pointer;
  width: 200px;
  padding: 24px 16px;
  background-color: #ffffff;
  border-radius: 16px;
  text-decoration: none;
  & > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`
const IconStyled = styled.div`
  height: 21.5px;
  width: 17.5px;
`

const DocsGrid = styled.div`
  ${(props) => props.theme.breakpoints.down("md")} {
    display: none;
  }
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding-inline-end: 144px;
  width: max-content;
  gap: 20px;
`
const StyledEmptyDiv = styled.div`
  width: 100%;
  height: 100%;
`

type DetailsProps = {
  apartment: ApartmentUmbraco
  type: "plan" | "specs" | "docs" | "gallery" | "rentplan"
}

function AccordionInnerDetails({ apartment, type }: DetailsProps) {
  const t = useTranslations("AccordionDetails")
  if (type === "plan") {
    return (
      <>
        {apartment.blueprintOfTheApartment ? (
          <ImageContainer
            desktopUrl={getUmbracoMediaUrl(apartment.blueprintOfTheApartment)}
            height={444}
            width={666}
            mobileHeight={280}
            objectFit="contain"
          />
        ) : (
          <StyledEmptyDiv />
        )}
      </>
    )
  }
  if (type === "specs") {
    return <ApartmentSpec apartment={apartment} />
  }
  if (type === "docs") {
    return (
      <StyledDocs>
        <BodyHeader>
          <NewText
            component={"h4"}
            desktopFontSize={18}
            color={sharedColors.black}
            desktopLineHeight={"22px"}
            mobileFontSize={16}
            desktopFontWeight={600}
            mobileLineHeight={"20px"}
          >
            {t("docs")}
          </NewText>
          <DocsGrid>
            {apartment.uploadContracts.map((el) => (
              <Document
                key={el.id}
                href={getUmbracoPDFUrl(el.url)}
                target="_blank"
                onClick={() =>
                  chosenProjectType(el.name, apartment?.propertyName, el.name)
                }
              >
                <IconStyled>
                  <FileIcon2 />
                </IconStyled>
                <NewText
                  component={"span"}
                  desktopFontSize={16}
                  desktopLineHeight={"23.04px"}
                  color={sharedColors.black}
                  borderBottom={"1px solid black"}
                >
                  {el.name}
                </NewText>
              </Document>
            ))}
          </DocsGrid>
          <Mobile>
            <Swiper
              key={1}
              style={{ padding: 0 }}
              pagination={true}
              modules={[Pagination]}
              slidesPerView={1.4}
              spaceBetween={16}
            >
              {apartment.uploadContracts.map((el) => (
                <SwiperSlide key={el.id}>
                  <Document
                    href={getUmbracoPDFUrl(el.url)}
                    target="_blank"
                    onClick={() =>
                      chosenProjectType(
                        el.name,
                        apartment?.propertyName,
                        el.name
                      )
                    }
                  >
                    <IconStyled>
                      <FileIcon2 />
                    </IconStyled>
                    <NewText
                      component={"span"}
                      desktopFontSize={16}
                      desktopLineHeight={"23.04px"}
                      color={sharedColors.black}
                      borderBottom={"1px solid black"}
                    >
                      {el.name}
                    </NewText>
                  </Document>
                </SwiperSlide>
              ))}
            </Swiper>
          </Mobile>
        </BodyHeader>
      </StyledDocs>
    )
  }
  if (type === "gallery") {
    return <ApartmentGallery apartment={apartment} />
  }
  if (type === "rentplan") {
    return <ApartmentPlan apartment={apartment} />
  }
}

export default AccordionInnerDetails
