import { StateType } from "../ProjectsLayout"

export function getUpdatedStateForKey(
  prevState: StateType,
  key: keyof StateType,
  optionId: number,
  newVisibility: boolean
): StateType {
  let updatedState: StateType = prevState
  if (optionId === 0) {
    updatedState = {
      ...prevState,
      [key]: prevState[key].map((opt) => {
        return {
          ...opt,
          visibility: opt.number === 0 ? true : false,
        }
      }),
    }
    console
  } else {
    updatedState = {
      ...prevState,
      [key]: prevState[key].map((opt) => {
        if (opt.number === 0) {
          return { ...opt, visibility: false }
        }

        return opt.number === optionId
          ? { ...opt, visibility: newVisibility }
          : opt
      }),
    }
  }
  switch (key) {
    case "designations":
      // Set visibility to false for the rest of the keys
      Object.keys(updatedState).forEach((k) => {
        if (k !== key) {
          const stateKey = k as keyof StateType
          updatedState[stateKey] = prevState[stateKey].map((opt) => ({
            ...opt,
            visibility: false,
          }))
        }
      })
      break
    case "statuses":
      Object.keys(updatedState).forEach((k) => {
        if (k === "cities" || k === "countries") {
          const stateKey = k as keyof StateType
          updatedState[stateKey] = prevState[stateKey].map((opt) => ({
            ...opt,
            visibility: false,
          }))
        }
      })

      break
    case "countries":
      Object.keys(updatedState).forEach((k) => {
        if (k === "cities") {
          const stateKey = k as keyof StateType
          updatedState[stateKey] = prevState[stateKey].map((opt) => ({
            ...opt,
            visibility: false,
          }))
        }
      })

      break
    default:
      break
  }

  return updatedState
}
