"use client"
import styled from "@emotion/styled"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import Link from "next/link"
import { useState } from "react"
import { useInView } from "react-intersection-observer"
import "swiper/css"
import { Swiper, SwiperSlide } from "swiper/react"
import {
  Container,
  Desktop,
  LinkedinIcon,
  Mobile,
  NewText,
  TransitionUp,
  transitionDelayTime,
  useIsDesktop,
} from "ui"
import { sharedColors } from "ui/theme/colors"
import {
  transitionEase,
  transitionTime,
  useInViewConfig,
} from "ui/transitions/utils"
import { aboutUsFilter, extractProperty, getUmbracoMediaUrl } from "utils"
import TeamContainerItem from "./TeamContainerItem"

const StyledLink = styled(Link)`
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
`
function CustomTabPanel(props: any) {
  const { children, value, index, ...rest } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...rest}
    >
      {value === index && children}
    </div>
  )
}

const StyledContainer = styled.div`
  .MuiTabs-root {
    margin-top: 12px;
  }
`

const StyledBottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const StyledPaginatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const StyledBulletsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 4px;
`

const StyledBullet = styled.div<{ active: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 2px;
  cursor: pointer;
  border: 1px solid black;
  border-width: ${(props) => (props.active ? 0 : "1px")};
  background-color: ${(props) =>
    props.active ? sharedColors.propBlue : "transparant"};
`

const StyledGridContainer = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 24px;
  row-gap: 40px;
  width: 100%;
`

const StyledSlideContainer = styled.div`
  width: 100%;
  height: 400px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  .expanded {
    transition: all ${transitionTime}s ${transitionEase};
    opacity: 0;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 100%;
    height: 400px;
    &:hover {
      .slide-info-box {
        transform: translateY(-310px);
        background-color: #2768a3;
      }
      button {
        display: none;
      }
      h1 {
        max-width: 100%;
      }
      .expanded {
        opacity: 1;
      }
    }
  }
`

const StyledInfoBox = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 310px;
  left: 0;
  right: 0;
  background-color: transparent;
  transition: all ${transitionTime}s ${transitionEase};
  button {
    position: absolute;
    top: 110px;
  }
`
const StyledInfoBoxInnerContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding: 10px 16px 16px 16px;
`

const StyledWrapperContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
  border-radius: 8px;
  top: 0;
  ${(props) => props.theme.breakpoints.up("md")} {
  }
`

const StyledDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: white;
  position: relative;
  z-index: 3;
`
const StyledButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
  z-index: 3;
  svg {
    z-index: 3;
  }
`

const StyledInfoTextContainer = styled.div<{ withLimit: boolean }>`
  overflow-x: hidden;
  overflow-y: scroll;
  margin: 10px 0;
  max-height: ${(props) => (props.withLimit ? "230px" : "325px")};
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  p {
    margin-top: 0px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 90%;
    p {
      margin-top: 0px;
    }
  }
`

const StyledItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 16px;
  height: auto !important;
  row-gap: 40px;
`

const StyledTeamsSlideWrapper = styled.div`
  background-color: #2768a3;
  min-height: 95px;
  height: 100%;
`

type Props = {
  data: any
  locale: string
  followMessage: string
}

export const TeamContainer: React.FC<Props> = ({
  data,
  locale,
  followMessage,
}) => {
  const title = extractProperty(data, "containerTitle")

  const innerData = extractProperty(data, "teamsBlock")?.map((item: any) => {
    return {
      tabTitle: extractProperty(item, "teamTitle"),
      teamInnerList: extractProperty(item, "teamListLine"),
    }
  })

  const teamInnerList = innerData?.map((item: any) => {
    return {
      tabTitle: item.tabTitle,
      teamInnerList: item.teamInnerList.map((item: any) =>
        extractProperty(item, "teamInnerList")
      ),
    }
  })

  const content = teamInnerList?.map((item: any) => {
    return {
      tabTitle: item.tabTitle,
      teamInnerList: item.teamInnerList.map((slideArray: any) =>
        slideArray.map((slide: any) => ({
          teamImage: extractProperty(slide, "teamImage"),
          teamName: extractProperty(slide, "teamName"),
          teamRole: extractProperty(slide, "teamRole"),
          teamDescription: extractProperty(slide, "teamDescription"),
          linkedin: extractProperty(slide, "linkedin"),
        }))
      ),
    }
  })

  const [value, setValue] = useState<number>(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    aboutUsFilter(content[newValue].tabTitle)
    setValue(newValue)
    setActiveIndex(0)
  }

  const [swiperRef, setSwiperRef] = useState<any>(null)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  // const [showContent, setShowContent] = useState(false)

  const { ref, inView } = useInView(useInViewConfig)
  const isDesktop = useIsDesktop()
  return (
    <StyledContainer ref={ref}>
      <Container>
        <NewText
          desktopFontSize={32}
          desktopFontWeight="400"
          desktopLineHeight="36px"
          mobileFontSize={24}
          mobileFontWeight="400"
          mobileLineHeight={"28px"}
          component="h1"
          color={"black"}
          maxWidth={"80%"}
        >
          {title}
        </NewText>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {content.map((tab: any, index: number) => (
            <Tab
              key={index.toString()}
              label={tab.tabTitle}
              style={{
                borderBottom:
                  value === index
                    ? `2px solid ${sharedColors.propBlue}`
                    : "none",
                fontSize: isDesktop ? "20px" : "16px",
              }}
            />
          ))}
        </Tabs>
      </Container>
      {content.map((tab: any, index: number) => {
        const combinedArray = tab.teamInnerList.reduce(
          (acc: any, curr: any) => acc.concat(curr),
          []
        )
        return (
          <CustomTabPanel key={index.toString()} value={value} index={index}>
            <Mobile>
              <Swiper
                style={{ paddingTop: "24px", paddingBottom: "24px" }}
                onSwiper={setSwiperRef}
                onSlideChange={(swiper) => {
                  setActiveIndex(swiper.activeIndex)
                }}
                slidesPerView={1.2}
                spaceBetween={8}
              >
                {tab.teamInnerList.map((item: any, index: number) =>
                  item.map((item: any) => (
                    <SwiperSlide
                      key={index.toString()}
                      style={{ height: "400px", width: "auto" }}
                    >
                      <TeamContainerItem
                        item={item}
                        followMessage={followMessage}
                      />
                    </SwiperSlide>
                  ))
                )}
              </Swiper>
              <Container>
                <StyledBottomContainer>
                  <StyledPaginatorContainer>
                    <StyledBulletsContainer>
                      {combinedArray.map((_: any, ind: any) => (
                        <StyledBullet
                          key={index.toString()}
                          onClick={() => {
                            swiperRef.slideTo(ind)
                          }}
                          active={ind === activeIndex}
                        />
                      ))}
                    </StyledBulletsContainer>
                  </StyledPaginatorContainer>
                </StyledBottomContainer>
              </Container>
            </Mobile>
            <Desktop>
              <Container>
                <StyledGridContainer>
                  {tab.teamInnerList.map((item: any, listIndex: number) => {
                    return (
                      <TransitionUp
                        key={listIndex}
                        $inView={inView}
                        $delay={listIndex * transitionDelayTime}
                      >
                        <StyledItem
                          key={listIndex.toString()}
                          style={{ height: 400, width: "auto" }}
                        >
                          {item.map((item: any) => (
                            <StyledSlideContainer
                              key={listIndex.toString()}
                              style={{
                                background: `url(${getUmbracoMediaUrl(
                                  item.teamImage
                                )})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundOrigin: "border-box",
                                backgroundPosition: "top center",
                              }}
                            >
                              <StyledInfoBox className={`slide-info-box`}>
                                <StyledInfoBoxInnerContainer>
                                  <StyledWrapperContainer>
                                    <StyledTeamsSlideWrapper />
                                  </StyledWrapperContainer>
                                  <NewText
                                    desktopFontSize={20}
                                    desktopFontWeight="bold"
                                    desktopLineHeight="28px"
                                    mobileFontSize={16}
                                    mobileFontWeight="bold"
                                    mobileLineHeight={"20px"}
                                    component="h1"
                                    color={"white"}
                                    style={{
                                      zIndex: 1,
                                      position: "relative",
                                    }}
                                  >
                                    {item.teamName}
                                  </NewText>
                                  <NewText
                                    desktopFontSize={16}
                                    desktopFontWeight="600"
                                    desktopLineHeight="24px"
                                    mobileFontSize={14}
                                    mobileFontWeight="400"
                                    mobileLineHeight={"20px"}
                                    component="h1"
                                    color={"white"}
                                    style={{
                                      zIndex: 1,
                                      position: "relative",
                                    }}
                                  >
                                    {item.teamRole}
                                  </NewText>

                                  <div className="expanded">
                                    <StyledInfoTextContainer
                                      withLimit={!!item?.linkedin?.[0]?.url}
                                    >
                                      <NewText
                                        desktopFontSize={18}
                                        desktopFontWeight="400"
                                        desktopLineHeight="26px"
                                        mobileFontSize={16}
                                        mobileFontWeight="400"
                                        mobileLineHeight={"20px"}
                                        component="div"
                                        color={"white"}
                                        style={{
                                          zIndex: 1,
                                          position: "relative",
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: item.teamDescription,
                                        }}
                                      />
                                    </StyledInfoTextContainer>

                                    {item?.linkedin?.[0]?.url && (
                                      <>
                                        <StyledDivider />
                                        <StyledLink
                                          href={item.linkedin?.[0]?.url || ""}
                                        >
                                          <StyledButtonContainer>
                                            <NewText
                                              desktopFontSize={18}
                                              desktopFontWeight="600"
                                              desktopLineHeight="24px"
                                              mobileFontSize={16}
                                              mobileFontWeight="400"
                                              mobileLineHeight={"20px"}
                                              component="h1"
                                              color={"white"}
                                              maxWidth={"80%"}
                                              style={{
                                                zIndex: 1,
                                                position: "relative",
                                              }}
                                            >
                                              {followMessage}
                                            </NewText>
                                            <LinkedinIcon />
                                          </StyledButtonContainer>
                                        </StyledLink>
                                      </>
                                    )}
                                  </div>
                                </StyledInfoBoxInnerContainer>
                              </StyledInfoBox>
                            </StyledSlideContainer>
                          ))}
                        </StyledItem>
                      </TransitionUp>
                    )
                  })}
                </StyledGridContainer>
              </Container>
            </Desktop>
          </CustomTabPanel>
        )
      })}
    </StyledContainer>
  )
}

export default TeamContainer
