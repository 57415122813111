import styled from "@emotion/styled"
import { yupResolver } from "@hookform/resolvers/yup"
import { Button, Checkbox, Grid, TextField } from "@mui/material"
import { ProjectStatus, postWebBmbyLead } from "api"
import { useLocale, useTranslations } from "next-intl"
import { LegacyRef, useRef, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import {
  CheckboxIcon,
  CheckedCheckboxIcon,
  CircleXIcon,
  NewText,
  SuccessFailState,
} from "ui"
import { sharedColors as colors, sharedColors } from "ui/theme/colors"
import { submitClick, submitError, submitSuccess } from "utils"
import { generateSchema } from "utils/Form/FormSchema"
import * as yup from "yup"
import { ctaMessage } from "../Contact/ContactForm"
import CustomHelperText from "../Contact/CustomHelperText"
import { ProjectsMessages } from "./Projects"

const CHARACTER_LIMIT = 500

const RECAPTCHA_KEY = process.env.RECAPTCHA_KEY
if (!RECAPTCHA_KEY) {
  throw new Error("Please provide the RECAPTCHA_KEY environment variable.")
}

type Props = {
  formSubjects: Record<string, string>
  onClose: () => void
  messages: ProjectsMessages
  formData?: {
    price: number | undefined
    currency: string | undefined
    area: number | undefined
    floor: string | undefined
    rooms: string | undefined
  }
  bmbyId?: number
  status?: any
  title?: string
  type?: string
}

const StyledCheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`

const CheckBoxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`

const FormContainer = styled.div`
  min-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => props.theme.breakpoints.down("md")} {
    min-width: 0;
    width: 100%;
    height: 100%;
  }
`
const StyledForm = styled.form`
  ${(props) => props.theme.breakpoints.down("md")} {
    height: 100%;
  }
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 2px solid ${colors.oldBrandBlue};
  background: white;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 660px;
  }
  .MuiFormHelperText-root {
    bottom: -25px;
    line-height: 1;
  }
`

const StyledInsideForm = styled.div`
  padding: 24px;
  /* max-height: 500px; */
  overflow-y: scroll;

  ${(props) => props.theme.breakpoints.up("md")} {
    max-height: none;
    overflow-y: auto;
    max-height: 600px;
  }
`

const StyledHeader = styled.div`
  height: 84px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2768a3;
  border-radius: 10px 10px 0 0;
  display: flex;
  padding: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 24px;
  }
`
const StyledButton = styled(Button)`
  width: 153px;
`

const BottomContainer = styled.div`
  border-top: 1px solid black;
  padding: 24px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.down("sm")} {
    .grecaptcha-badge {
      height: 45px !important;
    }
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    align-items: stretch;
    flex-direction: row;
    gap: 24px;
    margin-bottom: 0px;
  }
`
const TopDetailsDiv = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: max-content;
    flex-direction: column;
    align-items: start;
  }
`

const DetailNumbers = styled.div`
  display: flex;
  gap: 16px;
`
const BottomDetailsDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${(props) => props.theme.breakpoints.up("md")} {
    align-items: end;
  }
`
const PriceDiv = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledTitle = styled.div`
  margin-bottom: 16px;
`

const StyledCheckbox = styled(Checkbox, {
  shouldForwardProp: (prop) => prop !== "status",
})<{ status: boolean }>`
  padding: 1px 10px 0px 0;
  ${(props) =>
    !props.status
      ? `  rect {
    stroke: red;
  }`
      : ""}
`

// const ContactUsSubjectsArray: ContactUsSubjects[] = [
//   "AshtromGroupGeneral",
//   "AshtromGroupInvestorRelations",
//   "AshdarCustomerFinance_Simha",
//   "AshdarCustomerFinance_Ravit",
//   "AshdarCustomerFinance_Osnat",
//   "AshdarCustomerFinance_Odet",
//   "AshdarMarketingAndSales",
//   "AshdarUrbanRenewalDepartment",
//   "AshdarRightApprovalsAndMortgages",
//   "AshdarPlanningDepartment",
//   "AshdarCommonHouseRegistration",
//   "AshdarGeneral",
//   "AshdarEngineeringDepartment",
//   "PropertiesGeneral",
//   "PropertiesFinanceAndAccounting",
//   "PropertiesEngineeringDivision",
//   "PropertiesProjectMarketing",
//   "PropertiesAshtromPORT_ManagedOffices",
//   "ContractingGeneral",
//   "ResidentialRentingGeneral",
//   "FranchisingGeneral",
//   "IndustriesGeneral",
//   "RenewableEnergyGeneral",
//   "InterationalBusinessDevelopment",
//   "InterationalMarketing",
//   "InterationalProjects",
//   "InterationalGeneral",
//   "AshtromEngineeringAndConstructionGeneral",
//   "AshtromNorthGeneral",
//   "AshtromJerusalemGeneral",
//   "RoadsGeneral",
//   "AshkritGeneral",
//   "Basepyle",
//   "MLTGeneral",
// ]

const SmallDivider = styled.div`
  height: 14px;
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 24px;
  }
  width: 1px;
  background-color: #6f6f6f;
`

const StyledClose = styled(Button)`
  margin: 0;
  padding: 0;
  height: 24px;
  width: 24px;
  min-width: 0;
`
function ContactForm({
  onClose,
  messages,
  formData,
  bmbyId,
  status,
  type,
  title,
}: Props) {
  const [value] = useState("")

  const [formIsSubmitted, setFormIsSubmitted] = useState(false)
  const [submitState, setSubmitState] = useState<"success" | "fail">("fail")
  const reCaptchaRef = useRef<ReCAPTCHA | null>()
  const locale = useLocale()
  const schema = generateSchema(locale === "he" ? "he" : "en")
  const t = useTranslations("ProjectsPage")
  const tAccordion = useTranslations("AccordionDetails")
  const [checkedMarketing, setCheckedMarketing] = useState<boolean>(false)
  const [checkedCommercial, setCheckedCommercial] = useState<boolean>(false)
  const getPorjectStatues = () => {
    if (status === "building") return "Building"
    if (status === "Marketing and sales") return "MarketingAndSales"
    if (status === "Marketing and  Rental") return "MarketingAndRental"
    if (status === "planning") return "Planning"
    if (status === "Completed") return "Completed"
    if (status === "Occupancy") return "Occupancy"
    if (status === "management and operation") return "ManagementAndOperation"
  }

  type FormInput = yup.InferType<typeof schema>
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isSubmitted, isValid, isSubmitting },
  } = useForm<FormInput>({
    resolver: yupResolver(schema),
  })

  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    reCaptchaRef?.current?.reset()
    const recaptchaResponse =
      (await reCaptchaRef?.current?.executeAsync()) as string
    try {
      const senddata = {
        projectId: bmbyId || 0,
        name: data.fullName,
        email: data.email,
        phone: data.phone,
        comments: data.message || "",
        gRecaptchaResponse: recaptchaResponse,
        projectStatus: getPorjectStatues() as ProjectStatus,
      }
      await postWebBmbyLead(senddata)
      submitSuccess("שליחה", "השאירו פרטים ונחזור אליכם", true)
      setSubmitState("success")
      setFormIsSubmitted(true)
      reset()
    } catch (error) {
      setSubmitState("fail")
      setFormIsSubmitted(true)
      reset()
      submitError("שליחה", messages.forYou, error as any)
    }
  }

  return (
    <FormContainer>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <StyledHeader>
          <NewText
            component="p"
            desktopFontSize={32}
            desktopLineHeight="36px"
            mobileFontSize={24}
            mobileLineHeight="28px"
            color={colors.white}
          >
            {title ? title : messages.forYou}
          </NewText>
          <StyledClose onClick={onClose}>
            <CircleXIcon />
          </StyledClose>
        </StyledHeader>

        {formIsSubmitted ? (
          <div style={{ padding: "24px" }}>
            <SuccessFailState
              setIsSubmitted={setFormIsSubmitted}
              state={submitState}
              messages={messages}
              type={type}
              onClose={onClose}
            />
          </div>
        ) : (
          <>
            <StyledInsideForm>
              <StyledTitle>
                <NewText
                  component="p"
                  desktopFontSize={16}
                  desktopLineHeight="20px"
                  mobileFontSize={14}
                  mobileLineHeight="14px"
                  color={colors.black}
                >
                  {messages.required}
                </NewText>
              </StyledTitle>
              <Grid container columnSpacing={2} rowSpacing={4}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="fullName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={messages.fullName}
                        variant="filled"
                        error={!!errors.fullName}
                        helperText={
                          <CustomHelperText
                            render={isSubmitted || isSubmitting}
                            error={!!errors.fullName}
                            text={errors.fullName?.message}
                          />
                        }
                        fullWidth
                        inputProps={{ style: { fontSize: 16 } }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={messages.telephone}
                        variant="filled"
                        error={!!errors.phone}
                        inputProps={{ style: { fontSize: 16 } }}
                        helperText={
                          <CustomHelperText
                            render={isSubmitted || isSubmitting}
                            error={!!errors.phone}
                            text={errors.phone?.message}
                          />
                        }
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={messages.email}
                        variant="filled"
                        error={!!errors.email}
                        inputProps={{ style: { fontSize: 16 } }}
                        helperText={
                          <CustomHelperText
                            render={isSubmitted || isSubmitting}
                            error={!!errors.email}
                            text={errors.email?.message}
                          />
                        }
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="message"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={messages.messageOptional}
                        variant="filled"
                        multiline
                        minRows={3}
                        FormHelperTextProps={{ sx: { color: "black" } }}
                        inputProps={{
                          maxLength: CHARACTER_LIMIT,
                          style: { fontSize: 16, resize: "vertical" },
                        }}
                        error={!!errors.message}
                        helperText={`${value.length}/${CHARACTER_LIMIT}`}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CheckBoxesContainer>
                    <StyledCheckboxContainer>
                      <StyledCheckbox
                        icon={<CheckboxIcon />}
                        checkedIcon={<CheckedCheckboxIcon />}
                        sx={{
                          ":hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                        status={true}
                        value={checkedMarketing}
                        onChange={() => setCheckedMarketing((el) => !el)}
                      />

                      <div>
                        <NewText
                          desktopFontSize={18}
                          desktopFontWeight="400"
                          desktopLineHeight="25.92px"
                          mobileFontSize={14}
                          mobileFontWeight="400"
                          mobileLineHeight={"20px"}
                          component="p"
                          color={"black"}
                          maxWidth={"750px"}
                          dangerouslySetInnerHTML={{
                            __html: t("marketingMessage"),
                          }}
                        ></NewText>
                      </div>
                    </StyledCheckboxContainer>
                    <StyledCheckboxContainer>
                      <StyledCheckbox
                        icon={<CheckboxIcon />}
                        checkedIcon={<CheckedCheckboxIcon />}
                        sx={{
                          ":hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                        status={true}
                        value={checkedCommercial}
                        onChange={() => setCheckedCommercial((el) => !el)}
                      />

                      <div>
                        <NewText
                          desktopFontSize={18}
                          desktopFontWeight="400"
                          desktopLineHeight="25.92px"
                          mobileFontSize={14}
                          mobileFontWeight="400"
                          mobileLineHeight={"20px"}
                          component="p"
                          color={"black"}
                          maxWidth={"750px"}
                          dangerouslySetInnerHTML={{
                            __html: t("commercialMessage"),
                          }}
                        ></NewText>
                      </div>
                    </StyledCheckboxContainer>
                  </CheckBoxesContainer>
                  <div style={{ paddingTop: "16px" }}>
                    <NewText
                      desktopFontSize={18}
                      desktopFontWeight="400"
                      desktopLineHeight="25.92px"
                      mobileFontSize={14}
                      mobileFontWeight="400"
                      mobileLineHeight={"20px"}
                      component="p"
                      color={"black"}
                      maxWidth={"750px"}
                      dangerouslySetInnerHTML={{
                        __html: ctaMessage(t("ctaMessage") || ""),
                      }}
                    ></NewText>
                  </div>
                </Grid>
              </Grid>
            </StyledInsideForm>
            <BottomContainer>
              {formData ? (
                <TopDetailsDiv>
                  <NewText
                    style={{ marginInlineEnd: "auto" }}
                    component={"span"}
                    desktopFontWeight={600}
                    desktopFontSize={20}
                    desktopLineHeight={"22px"}
                    mobileFontSize={18}
                    mobileLineHeight={"20px"}
                    color={sharedColors.black}
                    columnMobile={true}
                  >
                    דירה
                  </NewText>
                  <DetailNumbers>
                    <NewText
                      component={"span"}
                      desktopFontSize={16}
                      desktopLineHeight={"25.92px"}
                      mobileFontSize={18}
                      mobileLineHeight={"14px"}
                      style={{ textWrap: "nowrap" }}
                      color={sharedColors.black}
                    >
                      {formData.rooms}{" "}
                      <span>{tAccordion("numberOfRooms")}</span>
                    </NewText>
                    <SmallDivider />
                    <NewText
                      component={"span"}
                      style={{ textWrap: "nowrap" }}
                      desktopFontSize={16}
                      desktopLineHeight={"25.92px"}
                      mobileFontSize={18}
                      mobileLineHeight={"14px"}
                      color={sharedColors.black}
                    >
                      {formData.floor} <span>{tAccordion("floor")}</span>
                    </NewText>
                    <SmallDivider />
                    <NewText
                      component={"span"}
                      desktopFontSize={16}
                      desktopLineHeight={"25.92px"}
                      mobileFontSize={18}
                      mobileLineHeight={"14px"}
                      style={{ textWrap: "nowrap" }}
                      color={sharedColors.black}
                    >
                      {formData.area} <span>מ״ר</span>
                    </NewText>
                    <SmallDivider />
                  </DetailNumbers>
                </TopDetailsDiv>
              ) : null}

              <BottomDetailsDiv>
                {formData ? (
                  <PriceDiv>
                    <NewText
                      component={"span"}
                      desktopFontSize={16}
                      style={{ textWrap: "nowrap" }}
                      desktopLineHeight={"20.16px"}
                      mobileFontSize={16}
                      mobileLineHeight={"20.16px"}
                      color={sharedColors.black}
                    >
                      מחיר דירה
                    </NewText>
                    {formData.price && (
                      <NewText
                        component={"span"}
                        desktopFontSize={32}
                        style={{ textWrap: "nowrap" }}
                        desktopLineHeight={"36px"}
                        mobileFontSize={24}
                        mobileLineHeight={"34px"}
                        desktopFontWeight={400}
                        color={sharedColors.black}
                      >
                        {new Intl.NumberFormat(locale).format(formData.price)}{" "}
                        <span style={{ fontSize: "18px" }}>
                          {formData.currency}
                        </span>
                      </NewText>
                    )}
                  </PriceDiv>
                ) : null}

                <StyledButton
                  type="submit"
                  variant="outlined"
                  size="large"
                  style={{ marginInlineStart: "auto" }}
                  onClick={() => {
                    submitClick("שליחה", messages.forYou),
                      (Object.keys(errors).length > 0 || !isValid) &&
                        submitError(
                          "שליחה",
                          messages.forYou,
                          errors?.fullName?.message ||
                            errors?.email?.message ||
                            errors.phone?.message ||
                            "Fields are mandatory"
                        )
                  }}
                >
                  {t("send")}
                </StyledButton>

                {RECAPTCHA_KEY && (
                  <ReCAPTCHA
                    ref={reCaptchaRef as LegacyRef<ReCAPTCHA> | undefined}
                    sitekey={RECAPTCHA_KEY}
                    size="invisible"
                    hl="iw"
                    tabIndex={-1}
                  />
                )}
              </BottomDetailsDiv>
            </BottomContainer>
          </>
        )}
      </StyledForm>
    </FormContainer>
  )
}

export default ContactForm
