"use client"

import { BlockRenderer, Container, CustomBreadcrumbs, TopBanner } from "ui"
import { getBreadCrumbs } from "utils"
import { findProperty } from "utils/findProperty"
import StockInvestorsMain from "./StockInvestorsMain"

type Props = {
  data: any
  stockGraph: any
  locale: string
  messages: {
    updated: string
    stockExWeb: string
  }
}

function StocksInvestors({ data, stockGraph, messages, locale }: Props) {
  const { selfObject, parents } = getBreadCrumbs(data, locale)
  const components = {
    topBanner: TopBanner,
    pgeMainTitle: StockInvestorsMain,
  }
  const blocks = findProperty(data.page, "blocks")?.blocks || []
  const hasBanner = blocks?.some(
    (block: any) =>
      block.blockType === "topBanner" ||
      block.blockType === "HeaderWithHeroAndText"
  )
  return (
    <>
      <Container>
        <CustomBreadcrumbs current={selfObject} parents={parents} />
      </Container>
      {blocks?.map((block: any, index: number) => {
        let additionalProps = {}
        if (block.blockType === "contentText") {
          additionalProps = {
            hasBanner,
          }
        } else if (block.blockType === "pgeMainTitle") {
          additionalProps = {
            stockGraph: stockGraph,
            messages: messages,
          }
        }
        return (
          <BlockRenderer
            key={index}
            blockType={block.blockType}
            blockData={block}
            components={components}
            props={additionalProps}
          />
        )
      })}
    </>
  )
}

export default StocksInvestors
