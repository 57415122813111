import { AllProjects } from "utils/projects"
import { StateType } from "../ProjectsLayout"
import { filterProjects } from "./filterProjects"

const getStateByFilterKey = (
  initialState: StateType,
  state: StateType,
  filterKey: keyof StateType,
  allowedCities: any,
  allowedcountry: any,
  allowedStatuses: any
) => {
  let newState = { ...state }
  switch (filterKey) {
    case "designations":
      newState = {
        ...state,
        ["cities"]: initialState.cities
          .filter(
            (city) =>
              allowedCities.some((item: any) => item === city.number) ||
              city.number === 0
          )
          .map((city) => ({ ...city, visibility: false })),
        ["countries"]: initialState.countries
          .filter(
            (country) =>
              allowedcountry.some((item: any) => item === country.number) ||
              country.number === 0
          )
          .map((country) => ({ ...country, visibility: false })),
        ["statuses"]: initialState.statuses
          .filter(
            (status) =>
              allowedStatuses.some((item: any) => item === status.number) ||
              status.number === 0
          )
          .map((status) => ({ ...status, visibility: false })),
      }
      break
    case "statuses":
      newState = {
        ...state,
        ["cities"]: initialState.cities
          .filter(
            (city) =>
              allowedCities.some((item: any) => item === city.number) ||
              city.number === 0
          )
          .map((city) => ({ ...city, visibility: false })),
        ["countries"]: initialState.countries
          .filter(
            (country) =>
              allowedcountry.some((item: any) => item === country.number) ||
              country.number === 0
          )
          .map((country) => ({ ...country, visibility: false })),
      }
      break
    case "countries":
      newState = {
        ...state,
        ["cities"]: initialState.cities
          .filter(
            (city) =>
              allowedCities.some((item: any) => item === city.number) ||
              city.number === 0
          )
          .map((city) => ({ ...city, visibility: false })),
      }
      break
    default:
      break
  }
  return newState
}

const setNewFilterList = (
  initialState: StateType,
  state: StateType,
  filterKey: keyof StateType,
  optionId: number,
  projects: AllProjects
): StateType => {
  const filteredProjects = filterProjects(projects as AllProjects, state)
  const allowedCities = [
    ...new Set(
      filteredProjects.flatMap((project: any) =>
        project.city.map((city: any) => city.id)
      )
    ),
  ]
  const allowedcountry = [
    ...new Set(
      filteredProjects.flatMap((project: any) =>
        project.country.map((country: any) => country.id)
      )
    ),
  ]
  const allowedStatuses = [
    ...new Set(
      filteredProjects.flatMap((project: any) =>
        project.projectStatuses.map((status: any) => status.id)
      )
    ),
  ]

  return getStateByFilterKey(
    initialState,
    state,
    filterKey,
    allowedCities,
    allowedcountry,
    allowedStatuses
  )
}

export default setNewFilterList
