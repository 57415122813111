import styled from "@emotion/styled"
import "swiper/css"
import "swiper/css/effect-coverflow"
import "swiper/css/effect-fade"
import "swiper/css/pagination"
import { Apartment } from "utils"
import ApartmentGallery from "../../ApartmentsForRent/ApartmentGallery"
import ApartmentPlan from "../../ApartmentsForRent/ApartmentPlan"
import ApartmentDocs from "./ApartmentDocs"
import ApartmentSpec from "./ApartmentSpec"

const StyledPlan = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
type DetailsProps = {
  apartment: Apartment
  type: "plan" | "specs" | "docs" | "gallery" | "rentplan"
  projectPage?: boolean
}

function AccordionInnerDetails({ apartment, type, projectPage }: DetailsProps) {
  if (type === "plan") {
    return (
      <StyledPlan>
        <ApartmentGallery apartment={apartment} />
        <ApartmentSpec apartment={apartment} projectPage={projectPage} />
      </StyledPlan>
    )
  }
  if (type === "specs") {
    return <ApartmentPlan apartment={apartment} projectPage={projectPage} />
  }
  if (type === "docs") {
    return <ApartmentDocs apartment={apartment} />
  }
  if (type === "gallery") {
    return <ApartmentGallery apartment={apartment} />
  }
  if (type === "rentplan") {
    return <ApartmentPlan apartment={apartment} />
  }
}

export default AccordionInnerDetails
