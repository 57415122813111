"use client"
import styled from "@emotion/styled"
import { CustomBreadcrumbs, LinkComponent, NewText } from "ui"
import { getNonUmbracoUrl } from "utils"

const StyledContainer = styled.div`
  padding: 32px 16px 32px 16px;
  max-width: 1280px;
  margin: 40px auto 0 auto;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 80px 120px 80px 120px;
  }
`

const StyledSitemapDiv = styled.div`
  margin-top: 20px;
`

const StyledLinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  ${(props) => props.theme.breakpoints.up("md")} {
    flex-wrap: wrap;
    flex-direction: row;
  }
`

const SubLinksWrapper = styled.div`
  /* margin-left: 34px; */
  a {
    margin-top: 10px;
  }
`

const StyledLinksDiv = styled.div`
  margin-top: 32px;
  margin-right: 10px;
  ${(props) => props.theme.breakpoints.up("md")} {
    min-width: 50px;
    margin-right: 20px;
    margin-top: 40px;
  }
`

function Sitemap({ data, locale }: any) {
  const nodes = data?.sitemap?.nodes
  return (
    <StyledContainer>
      <CustomBreadcrumbs
        current={{
          title: locale === "he" ? "מפת אתר" : "Sitemap",
          url: `/sitemap`,
        }}
        parents={[{ title: locale === "he" ? "ראשי" : "Hompage", url: "/" }]}
      />
      <NewText
        desktopFontSize={40}
        desktopFontWeight="400"
        desktopLineHeight="110%"
        mobileFontSize={32}
        mobileFontWeight="400"
        mobileLineHeight={"112.5%"}
        component="h1"
        color={"clack"}
        margin={"40px 0"}
      >
        מפת אתר
      </NewText>

      <StyledSitemapDiv>
        <StyledLinksWrapper>
          {nodes?.map((node: any, index: number) => (
            <StyledLinksDiv key={index.toString()}>
              <LinkComponent
                color="#0915BC"
                text={node?.name || ""}
                link={getNonUmbracoUrl(node?.url) || ""}
                fontSize={18}
                fontWeight={600}
              />
              {node?.children?.length ? (
                <SubLinksWrapper>
                  {node?.children?.map((child: any, ind: number) => (
                    <LinkComponent
                      key={ind.toString()}
                      text={child?.name || ""}
                      link={node?.url || ""}
                      fontSize={16}
                      fontWeight={400}
                      color={"black"}
                      sx={{ textDecoration: "underline" }}
                    />
                  ))}
                </SubLinksWrapper>
              ) : null}
            </StyledLinksDiv>
          ))}
        </StyledLinksWrapper>
      </StyledSitemapDiv>
    </StyledContainer>
  )
}

export default Sitemap
