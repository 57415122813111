"use client"

import { GetPageByRouteQuery } from "apollo"
import { BlockRenderer, Container, CustomBreadcrumbs, TopBanner } from "ui"
import { getBreadCrumbs } from "utils"
import { findProperty } from "utils/findProperty"
import Certificates from "./Certificates"
import TitleContent from "./TitleContent"

type Props = {
  data: GetPageByRouteQuery
  locale: string
}

function Standards({ data, locale }: Props) {
  const { parents, selfObject } = getBreadCrumbs(data, locale)
  const components = {
    topBanner: TopBanner,
    contentText: TitleContent,
    certificates: Certificates,
    certificates1: Certificates,
  }

  const blocks = findProperty(data.page, "blocks")?.blocks || []
  const hasBanner = blocks?.some(
    (block: any) =>
      block.blockType === "topBanner" ||
      block.blochType === "HeaderWithHeroAndText"
  )
  return (
    <>
      <Container>
        <CustomBreadcrumbs current={selfObject} parents={parents} />
      </Container>
      {blocks?.map((block: any, index: number) => {
        let additionalProps = {}
        if (block.blockType === "contentText") {
          additionalProps = {
            hasBanner: hasBanner,
            height: "500px",
          }
        } else if (block.blockType === "numbersComponent1") {
          additionalProps = {
            accentColor: "black",
          }
        } else {
          additionalProps = {
            height: "500px",
          }
        }
        return (
          <BlockRenderer
            key={index}
            blockType={block.blockType}
            blockData={block}
            components={components}
            locale={locale}
            props={additionalProps}
          />
        )
      })}
    </>
  )
}

export default Standards
