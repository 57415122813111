import styled from "@emotion/styled"
import { useState } from "react"
import { Container, NewText, ReportsWrapperSmall } from "ui"
import TabPanel from "ui/TabPanel"
import TabTitle from "ui/TabTitle"
import { sharedColors as colors } from "ui/theme/colors"
import { extractProperty } from "utils/extractProperty"

const StyledPageDiv = styled.div<{ $costumPadding?: string }>`
  padding-top: 32px;
  padding-bottom: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: ${(p) => (p.$costumPadding ? p.$costumPadding : "80px")};
    padding-bottom: ${(p) => (p.$costumPadding ? p.$costumPadding : "80px")};
  }
`
type Props = {
  data: any
  view: string
  eventName?: string
  children: React.ReactNode
  costumPadding?: string
}

const StyledTabsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  margin-top: 40px;
  ${(props) => props.theme.breakpoints.up("md")} {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 32px;
  }
`

function FinancialReportsTab({
  data,
  view,
  eventName = "",
  children,
  costumPadding,
}: Props) {
  const [value, setValue] = useState(0)
  const title = extractProperty(data, "mainTitle")
  const tabs = extractProperty(data, "reportsTabs")

  return (
    <Container>
      <StyledPageDiv $costumPadding={costumPadding}>
        {children}
        <NewText
          component={"h1"}
          desktopFontSize={40}
          desktopLineHeight={"44px"}
          desktopFontWeight={400}
          mobileFontSize={32}
          mobileLineHeight={"36px"}
          color={colors.black}
          marginTop={"32px"}
        >
          {title}
        </NewText>
        <TabTitle
          tabs={tabs}
          setValue={setValue}
          value={value}
          eventName={eventName}
        />
        {tabs.map((block: any, index: number) => (
          <TabPanel value={value} index={index} key={"tab" + index.toString()}>
            <StyledTabsWrapper>
              {block.properties
                .find((prop: any) => prop.propertyName === "reportsPicker")
                ?.propertyValue?.blocks?.map((data: any, k: number) => {
                  return (
                    <ReportsWrapperSmall
                      key={k.toString()}
                      data={data}
                      view={view}
                      mainTitle={title}
                    />
                  )
                })}
            </StyledTabsWrapper>
          </TabPanel>
        ))}
      </StyledPageDiv>
    </Container>
  )
}

export default FinancialReportsTab
