import styled from "@emotion/styled"
import { NewText } from "ui"
import { sharedColors } from "ui/theme/colors"

const StyledNumberDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  align-items: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 64px;
  }
`

const StyledNumberItem = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.up("md")} {
    align-items: center;
  }
`

const StyledTopText = styled.div`
  display: flex;
  align-items: flex-end;
  p {
    white-space: nowrap;
  }
`

const StyledBottomText = styled.div`
  text-align: left;
  ${(props) => props.theme.breakpoints.up("md")} {
    text-align: center;
    display: flex;
    justify-content: center;
  }
`

type Props = {
  data: any
}
const NewNumberGrid = ({ data }: Props) => {
  if (!data || !data.length) {
    return null
  }
  return (
    <StyledNumberDiv>
      {data.map((item: any, index: number) => {
        return (
          <StyledNumberItem key={index}>
            <StyledTopText>
              <NewText
                component={"p"}
                desktopFontSize={48}
                desktopFontWeight={600}
                color="#2768A3"
                mobileFontSize={40}
                mobileLineHeight={"46px"}
              >
                {item.number}
                <NewText
                  component={"span"}
                  desktopFontSize={18}
                  desktopFontWeight={400}
                  color={sharedColors.black}
                  paddingLeft={"4px"}
                  desktopLineHeight={1.6}
                >
                  {item.textNextToNumber}
                </NewText>
              </NewText>
            </StyledTopText>
            <StyledBottomText>
              <NewText
                component={"p"}
                desktopFontSize={14}
                desktopFontWeight={400}
                color={sharedColors.black}
              >
                {item.text}
              </NewText>
            </StyledBottomText>
          </StyledNumberItem>
        )
      })}
    </StyledNumberDiv>
  )
}

export default NewNumberGrid
