import styled from "@emotion/styled"
import { Container, LinkButton, NewText } from "ui"
import ImageWithAlt from "ui/ImageWithAlt"
import { sharedColors as colors } from "ui/theme/colors"
import { extractImage, extractProperty } from "utils"

type Props = {
  data: any
}

const StyledRightDiv = styled.div`
  width: 90%;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 65%;
  }
`

const StyledLogoDiv = styled.div``

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-wrap: wrap;
    flex-direction: column;
    gap: 24px;
  }
`

const StyledLeftDiv = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`

function SingleArm({ data }: Props) {
  const logo = extractImage(data, "logo")
  const text = extractProperty(data, "text")
  const button = extractProperty(data, "button")

  return (
    <Container style={{ margin: "80px auto" }}>
      <ImageWithAlt
        image={logo}
        style={{ objectFit: "contain" }}
        width={210}
        height={80}
      />
      <StyledWrapper>
        <StyledRightDiv>
          <StyledLogoDiv>
            <NewText
              dangerouslySetInnerHTML={{ __html: text }}
              component={"div"}
              desktopFontSize={24}
              desktopLineHeight={"26px"}
              color={colors.black}
              mobileFontSize={16}
            />
          </StyledLogoDiv>
        </StyledRightDiv>
        <StyledLeftDiv>
          <LinkButton link={button} mainTitle="single Arm" />
        </StyledLeftDiv>
      </StyledWrapper>
    </Container>
  )
}

export default SingleArm
