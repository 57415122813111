"use client"
import styled from "@emotion/styled"
import "swiper/css"
import { Container } from "ui"
import { extractProperty } from "utils"
import StandardCard from "./StandardCard"

const StyledContainer = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  padding-top: 0;
  padding-bottom: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 24px;
    row-gap: 40px;
    padding-top: 40px;
    padding-bottom: 80px;
  }
`

type Props = {
  data: any
  locale: string
}

export const Certificates: React.FC<Props> = ({ data, locale }) => {
  const standardsAndCertificates = extractProperty(
    data,
    "StandardsAndCertificates"
  )
  const list = Array.isArray(standardsAndCertificates)
    ? standardsAndCertificates.map((i: any) => {
        return {
          logo: extractProperty(i, "smallImage"),
          title: extractProperty(i, "standardsTitle"),
          date: extractProperty(i, "certificatesDate"),
          content: extractProperty(i, "certificatesDescription"),
          image: extractProperty(i, "largeImage"),
          link: extractProperty(i, "link"),
        }
      })
    : []

  return (
    <Container>
      <StyledContainer>
        {list?.map((i: any, key: number) => (
          <StandardCard key={key.toString()} {...i} locale={locale} />
        ))}
      </StyledContainer>
    </Container>
  )
}
export default Certificates
