"use client"

import { useEffect } from "react"
import {
  Groups,
  MatchedStatuses,
  PriorityData,
  ProjectPage,
  pageview,
} from "utils"
import { RelatedProjects } from "utils/utils"
import Marketing from "./Marketing/Marketing"
import StandardProject from "./StandardProject"

export type ProjectsMessages = {
  contactUs: string
  priceOffer: string
  forYou: string
  required: string
  subject: string
  fullName: string
  telephone: string
  email: string
  messageOptional: string
  consentMessage: string
  privacy: string
  terms: string
  send: string
  groups: string
  collaborators: string
  entr: string
  leaveDetails: string
  close: string
  virtualTour: string
  sales: string
  salesCenter: string
  toProject: string
  sucessTitle: string
  sucessText: string
  contactSucessTitle: string
  contactSucessText: string
  rentforyou?: string
  successTitleRent?: string
  successTextRent?: string
}

type Props = {
  project: ProjectPage
  relatedProjects?: RelatedProjects
  statuses: MatchedStatuses
  groups: Groups
  formSubjects: Record<string, string>
  designations: string[]
  cities: string[]
  countries: string[]
  locale: string
  messages: ProjectsMessages
  propertyTypes: { id: number; value: string }[]
  priorityData?: PriorityData
  companyName?: string
  subcompany?: string
  toLobby?: string
}

function Projects({
  locale,
  project,
  statuses,
  groups,
  formSubjects,
  designations,
  cities,
  countries,
  messages,
  propertyTypes,
  relatedProjects,
  companyName,
  subcompany,
  priorityData,
  toLobby,
}: Props) {
  const { projectType } = project

  // const isLegacy = projectType === "מורשת"
  // const isPlanning = projectType === "תכנון ובנייה"
  const isPropertyMarketing = projectType === "שיווק נכסים"
  const isApartmentMarketing = projectType === "שיווק דירות"
  const entitledProject = projectType === "זכאים"

  useEffect(() => {
    pageview(companyName || "", subcompany || "", projectType || "", locale)
  }, [])

  if (isApartmentMarketing || isPropertyMarketing || entitledProject) {
    return (
      <Marketing
        toLobby={toLobby}
        cities={cities}
        countries={countries}
        designations={designations}
        formSubjects={formSubjects}
        groups={groups}
        locale={locale}
        statuses={statuses}
        priorityData={priorityData}
        projectData={project}
        messages={messages}
        relatedProjects={relatedProjects}
        propertyTypes={propertyTypes}
      />
    )
  } else {
    return (
      <StandardProject
        toLobby={toLobby}
        cities={cities}
        countries={countries}
        designations={designations}
        formSubjects={formSubjects}
        groups={groups}
        locale={locale}
        messages={messages}
        project={project}
        propertyTypes={propertyTypes}
        statuses={statuses}
      />
    )
  }
}

export default Projects
