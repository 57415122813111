"use client"
import styled from "@emotion/styled"
import { useInView } from "react-intersection-observer"
import { Container, Desktop, Mobile, useInViewConfig } from "ui"
import { extractProperty } from "utils"
import HistorySlider from "./HistorySlider"
import HistoryText from "./HistoryText"

const StyledGrid = styled.div<{ $switch?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  gap: 16px;
  margin-bottom: 40px;
  ${(props) => props.theme.breakpoints.up("md")} {
    display: grid;
    grid-template-columns: ${(props) =>
      props.$switch ? "460px 1fr" : "1fr 460px"};
    gap: 60px;
  }
`

const StyledSection = styled.section`
  ${(props) => props.theme.breakpoints.up("md")} {
    scroll-margin-top: 100px;
  }
`

type Props = {
  data: any
}

function HistoryBlock({ data }: Props) {
  const dynamicSlider = extractProperty(data, "dynamicSlider")
  const switchingSides = extractProperty(data, "switchingSides")
  const years = extractProperty(data, "years")
  const title = extractProperty(data, "title")
  const text = extractProperty(data, "text")
  const { ref, inView } = useInView(useInViewConfig)

  return (
    <Container containerRef={ref}>
      <StyledSection id={years}>
        <Mobile>
          <StyledGrid>
            <HistoryText
              years={years}
              title={title}
              text={text}
              inView={inView}
            />
            <HistorySlider data={dynamicSlider[0]} inView={inView} />
          </StyledGrid>
        </Mobile>
        <Desktop>
          {switchingSides ? (
            <StyledGrid $switch={switchingSides}>
              <HistorySlider data={dynamicSlider[0]} inView={inView} />
              <HistoryText
                years={years}
                title={title}
                text={text}
                inView={inView}
              />
            </StyledGrid>
          ) : (
            <StyledGrid>
              <HistoryText
                years={years}
                title={title}
                text={text}
                inView={inView}
              />
              <HistorySlider data={dynamicSlider[0]} inView={inView} />
            </StyledGrid>
          )}
        </Desktop>
      </StyledSection>
    </Container>
  )
}

export default HistoryBlock
