"use client"

import styled from "@emotion/styled"
import { Button, Tab, Tabs } from "@mui/material"
import { GetPageByRouteQuery } from "apollo"
import { useTranslations } from "next-intl"
import { useState } from "react"
import "swiper/css"
import { Container, CustomBreadcrumbs, Desktop, Mobile, NewText } from "ui"
import { filterClick, getBreadCrumbs, getNonUmbracoUrl } from "utils"
import { extractProperty } from "utils/extractProperty"
import ArticleItem from "./ArticleItiem"

function CustomTabPanel(props: any) {
  const { children, value, index, ...rest } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{
        margin: 0,
        padding: 0,
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
      {...rest}
    >
      {value === index && children}
    </div>
  )
}

const StyledContentContainer = styled.div`
  padding-bottom: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-bottom: 80px;
  }
`

const StyledArticlesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    display: grid;
    gap: 40px;
    grid-template-columns: 1fr 1fr 1fr;
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    padding-top: 40px;
  }
`
const StyledTop = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  ${(props) => props.theme.breakpoints.up("md")} {
  }
`
const StyledTopText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: center;
  padding-bottom: 26px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-bottom: 0;
  }
`

const StyledTabstContainer = styled(Tabs)`
  height: auto;
  min-height: 0;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 24px;
    padding-bottom: 24px;
  }
`

const StyledMoreButton = styled(Button)`
  text-decoration: none;
  font-size: 14px;
  line-height: 20.16px;
  color: black;
  align-self: center;
  border-bottom: 1px solid #000000;
  width: fit-content;
  margin-bottom: 50px;
  margin-top: 20px;
  border-radius: 0;
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 16px;
    line-height: 23.04px;
    margin-top: 40px;
  }
  :hover {
    text-decoration: none;
  }
`

const StyledTab = styled(Tab)<{ active: boolean }>`
  font-size: 16px;
  padding: 0px 8px 8px 8px;
  margin: 0;
  min-width: 110px;
  height: auto;
  min-height: 0;
  font-size: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 20px;
  }
  border-bottom: ${(props) =>
    props.active ? `2px solid ${props.theme.colors.oldBrandBlue}` : "none"};
`
type Props = {
  pageData: GetPageByRouteQuery
  articles: any
  locale: string
  websites: any
}

type Und = string | undefined
export function findBlock({
  blocks,
  type,
}: {
  blocks: { blockType: string }[]
  type: string
}) {
  return blocks.find((el) => el.blockType === type)
}
function convertArticlesData(edges: any, websites: any) {
  const categories: Record<
    string,
    {
      text: Und
      imageDesktop: Und
      imageMobile: Und
      title: Und
      subTitle: Und
      logo: Und
      date: Und
      url: string
      articlePicDescktop: string
      articlePicMobile: string
      associate: any
      associateText?: string
    }[]
  > = {}
  edges.forEach(({ node }: any) => {
    const blocks = extractProperty(node, "blocks") as { blockType: string }[]
    const topBanner = findBlock({ blocks, type: "topBanner" })
    const articlePic = findBlock({ blocks, type: "articlePic" })
    const articleHeader = findBlock({ blocks, type: "articleHeader" })
    const category = extractProperty(articleHeader, "articleCategory") as string
    if (!categories[category]) {
      categories[category] = []
    }
    const url = node.url as string
    const content = findBlock({ blocks, type: "contentText" })
    const text = extractProperty(content, "innerText") as Und
    const imageDesktop = extractProperty(topBanner, "desktopImage") as Und
    const imageMobile = extractProperty(topBanner, "mobileImage") as Und
    const articlePicDescktop = extractProperty(articlePic, "articlePicDescktop")
    const articlePicMobile = extractProperty(articlePic, "articlePicMobile")
    const title = extractProperty(articleHeader, "articleMainTitle") as Und
    const subTitle = extractProperty(articleHeader, "activitySubTitle") as Und
    const associate = extractProperty(articleHeader, "associate")
    const associateText = websites?.filter(
      (website: any) => website.id === associate[0]?.id
    )[0]?.namedProperties?.siteName.value
    const logo = extractProperty(articleHeader, "imageLogo") as Und
    const date = extractProperty(articleHeader, "articleDate") as Und
    const object = {
      text,
      imageDesktop,
      imageMobile,
      title,
      logo,
      date,
      url,
      subTitle,
      articlePicDescktop,
      articlePicMobile,
      associate,
      associateText,
    }
    categories[category].push(object)
  })
  return categories
}
function LobbyArticles({ pageData, articles, locale, websites }: Props) {
  const { selfObject, parents } = getBreadCrumbs(pageData, locale)
  const pageTitle = extractProperty(pageData.page, "pageTitle")
  const pageTextContent = extractProperty(pageData.page, "pageTextContent")
  const [value, setValue] = useState<number>(0)
  const [visibleCount, setVisibleCount] = useState(9)
  const t = useTranslations("Articles")
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    filterClick(Object.keys(categories)[newValue], "פילטר מגזין")
    setValue(newValue)
  }
  const categories = convertArticlesData(
    articles.data.components.edges,
    websites
  )
  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 9)
  }

  return (
    <Container>
      <CustomBreadcrumbs current={selfObject} parents={parents} />
      <Mobile>
        <StyledTopText>
          <NewText
            desktopFontSize={40}
            desktopFontWeight="400"
            desktopLineHeight="110%"
            mobileFontSize={32}
            mobileFontWeight="400"
            mobileLineHeight={"36px"}
            component="h1"
            color={"black"}
          >
            {pageTitle}
          </NewText>
          <NewText
            desktopFontSize={24}
            desktopFontWeight="400"
            desktopLineHeight="110%"
            mobileFontSize={18}
            mobileFontWeight="400"
            mobileLineHeight={"24px"}
            component="div"
            color={"black"}
            dangerouslySetInnerHTML={{ __html: pageTextContent }}
          />
        </StyledTopText>
      </Mobile>

      <Desktop>
        <StyledTop>
          <StyledTopText>
            <NewText
              desktopFontSize={40}
              desktopFontWeight="400"
              desktopLineHeight="110%"
              mobileFontSize={32}
              mobileFontWeight="400"
              mobileLineHeight={"36px"}
              component="h1"
              color={"black"}
            >
              {pageTitle}
            </NewText>
            <NewText
              desktopFontSize={24}
              desktopFontWeight="400"
              desktopLineHeight="110%"
              mobileFontSize={18}
              mobileFontWeight="400"
              mobileLineHeight={"24px"}
              component="div"
              color={"black"}
              dangerouslySetInnerHTML={{ __html: pageTextContent }}
            />
          </StyledTopText>
        </StyledTop>
      </Desktop>
      <StyledTabstContainer
        value={value}
        variant="scrollable"
        allowScrollButtonsMobile
        onChange={handleChange}
        scrollButtons="auto"
        aria-label="basic tabs example"
      >
        {Object.keys(categories).map((tab: any, index: number) => (
          <StyledTab
            active={value === index}
            key={index.toString()}
            label={tab}
          />
        ))}
      </StyledTabstContainer>

      <StyledContentContainer>
        {Object.keys(categories).map((tab: any, index: number) => (
          <CustomTabPanel key={index.toString()} value={value} index={index}>
            <StyledArticlesContainer>
              {categories[tab]
                ?.sort((a, b) => {
                  const dateA = new Date(a.date as any).getTime()
                  const dateB = new Date(b.date as any).getTime()
                  return dateB - dateA
                })
                .map((i, k: number) => {
                  if (k < visibleCount)
                    return (
                      <ArticleItem
                        key={k.toString()}
                        logo={i.logo || ""}
                        title={i.title || ""}
                        subTitle={i.subTitle || ""}
                        associationText={i.associateText}
                        date={i.date || ""}
                        imageDesktop={i.articlePicDescktop || ""}
                        imageMobile={i.articlePicMobile || ""}
                        content={i.text || ""}
                        link={getNonUmbracoUrl(i.url)}
                      />
                    )
                })}
            </StyledArticlesContainer>
            {visibleCount < categories[tab].length && (
              <StyledMoreButton variant="text" onClick={handleShowMore}>
                {t("showMore")}
              </StyledMoreButton>
            )}
          </CustomTabPanel>
        ))}
      </StyledContentContainer>
    </Container>
  )
}

export default LobbyArticles
