import styled from "@emotion/styled"
import { Box } from "@mui/material"
import { NewText, useTransitionUpStyles } from "ui"
import { sharedColors } from "ui/theme/colors"
import { delayTime, styleObjectToCss } from "ui/transitions/utils"

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const StyledNumbers = styled.div`
  border-left: 2px solid ${sharedColors.propBlue};
  background: transparent;
  color: ${sharedColors.black};
  padding: 0px 8px;
`

type Props = {
  years: string
  title: string
  text: string
  inView: boolean
}

function HistoryText({ years, title, text, inView }: Props) {
  const yearTransitionStyles = useTransitionUpStyles(inView)
  const titleTransitionStyles = useTransitionUpStyles(inView, delayTime)
  const descriptionTransitionStyles = useTransitionUpStyles(
    inView,
    delayTime * 1.5
  )
  return (
    <StyledContainer>
      {years && (
        <Box display="flex" sx={{ ...yearTransitionStyles }}>
          <StyledNumbers>
            <NewText
              color={sharedColors.black}
              mobileFontSize={18}
              mobileLineHeight="25.92px"
              desktopFontSize={20}
              desktopFontWeight="600"
              desktopLineHeight="28.8px"
              component="p"
            >
              {years}
            </NewText>
          </StyledNumbers>
        </Box>
      )}
      <div>
        <NewText
          color={sharedColors.black}
          mobileFontSize={24}
          mobileLineHeight="28px"
          desktopFontSize={32}
          desktopLineHeight="36px"
          component="p"
          cssString={styleObjectToCss(titleTransitionStyles)}
        >
          {title}
        </NewText>
        <NewText
          color={sharedColors.black}
          mobileFontSize={18}
          mobileLineHeight="26px"
          desktopFontSize={24}
          desktopLineHeight="35px"
          component="div"
          dangerouslySetInnerHTML={{ __html: text }}
          cssString={styleObjectToCss(descriptionTransitionStyles)}
        />
      </div>
    </StyledContainer>
  )
}

export default HistoryText
