import { Dispatch, SetStateAction, useState } from "react"
import { jobFilter } from "utils"
import { ProjectFilters } from "utils/projects"
import { getUpdatedStateForKey } from "./FiltersUtils/getUpdatedStateForKey"
import setNewFilterList from "./FiltersUtils/setNewFilterList"
import NewFilters from "./NewFilters"
import { StateType } from "./ProjectsLayout"
import { initialState as cleanState } from "./ProjectsLayout"

const countFilters = (state: StateType) => {
  let visibleCount = 0
  let totalCount = 0

  Object.keys(state).forEach((key) => {
    // Assuming you want to include every filter for counting
    if (key !== "designations" && key !== "subsidiaries") {
      const items = state[key as keyof StateType]
      const realItems = items.filter((item) => item.number !== 0)
      totalCount += realItems.length // Add to total count of filters

      const visibleItems = items.filter(
        (item) => item.visibility && item.number !== 0
      )
      visibleCount += visibleItems.length // Add to count of visible filters
    }
  })

  return { visibleCount, totalCount }
}

const allowedFilters = {
  group: ["designations", "statuses", "cities", "subsidiaries", "countries"],
  construction: ["designations", "cities"],
  properties: ["designations", "statuses"],
  ashdar: ["designations", "statuses", "cities"],
  industries: ["designations", "cities"],
  rent: ["designations", "statuses", "cities"],
  international: ["designations", "statuses", "cities"],
}

type Props = {
  state: StateType
  setState: Dispatch<SetStateAction<StateType>>
  filters: ProjectFilters
  homepage?: boolean
  element?: string
  locale?: string
  homepageUrl?: string
  setIsFilterApplied?: any
  page: string
  projects: any
  messages: {
    showAll: string
    all: string
    showResults: string
    noProjectsFound: string
    toProject: string
    filters: string
    moreCategory: string
    allProjects: string
  }
  interimState: StateType
  setInterimState: Dispatch<SetStateAction<StateType>>
}
function Filters({
  setState,
  state,
  filters,
  setIsFilterApplied = () => null,
  page,
  projects,
  messages,
  interimState,
  setInterimState,
}: Props) {
  const [initialState] = useState(state)

  const handleSelectChange = (
    key: keyof StateType,
    optionId: number,
    newVisibility: boolean,
    designation?: boolean,
    cleanSlate?: boolean
  ) => {
    let updatedState = getUpdatedStateForKey(
      interimState,
      key,
      optionId,
      newVisibility
    )
    if (cleanSlate) {
      setInterimState(initialState)
      setState(initialState)
      setIsFilterApplied(false)
    } else {
      updatedState = setNewFilterList(
        initialState,
        updatedState,
        key,
        optionId,
        projects
      )
      setInterimState(updatedState)
    }

    if (designation) {
      setState(updatedState)
      setIsFilterApplied(true)
    }
  }
  const handleOtherFilters = () => {
    setState(interimState)
    setIsFilterApplied(true)
  }

  const handleClearInnerFilters = () => {
    // const updatedObject = {
    //   ...interimState,
    //   statuses: interimState.statuses.map((status) => ({
    //     ...status,
    //     visibility: false,
    //   })),
    //   countries: interimState.countries.map((country) => ({
    //     ...country,
    //     visibility: false,
    //   })),
    //   cities: initialState.cities.map((city) => ({
    //     ...city,
    //     visibility: false,
    //   })),
    // }
    setInterimState(cleanState(filters))
    setState(cleanState(filters))
    setIsFilterApplied(false)
    jobFilter("איפוס", "איפוס", "Filter - projects")
  }
  const { totalCount, visibleCount } = countFilters(state)

  return (
    <>
      {(allowedFilters as any)[page] ? (
        <>
          <NewFilters
            onChange={(optionId, newVisibility, designation, cleanSlate) => {
              handleSelectChange(
                "designations",
                optionId,
                newVisibility,
                designation,
                cleanSlate
              )
            }}
            otherOnChange={(filterKey, optionId, newVisibility) => {
              handleSelectChange(filterKey, optionId, newVisibility)
            }}
            handleOtherFilters={handleOtherFilters}
            interimState={interimState}
            allowedFilters={allowedFilters}
            page={page}
            totalCount={totalCount}
            count={visibleCount}
            filters={filters}
            label={filters.designations.label}
            messages={messages}
            handleClearFilters={handleClearInnerFilters}
            handleClearInnerFilters={handleClearInnerFilters}
            countFilters={countFilters}
          />
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default Filters
