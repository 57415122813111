"use client"
import { GetPageQuery } from "apollo"

import styled from "@emotion/styled"
import { BondsTableResponse, GraphStockResponse, SlimStockDto } from "api"
import {
  ActiveArms,
  BlockRenderer,
  Container,
  CustomBreadcrumbs,
  TopBanner,
} from "ui"
import { findProperty, getBreadCrumbs } from "utils"
import ImmediateReports from "./ImmediateReports"
import InvestorsNumber from "./InvestorsNumber"
import Reports from "./Reports"
import ShareBlock from "./ShareBlock"
import SingleArm from "./SingleArm"
import StockBanner from "./StockBanner"
import TitleWithButton from "./TitleWithButton"

const StyledContainer = styled.div`
  padding-top: 38px;
`
export type InvestoreRelationsMessages = {
  stockInfo: string
  bonds: string
  stockTicker: string
  price: string
  change: string
  volume: string
  ytm: string
  averageDuration: string
  weekRange: string
  lastUpdate: string
  updated: string
  stockExWeb: string
  tostock: string
  tobonds: string
  lasttransaction: string
  StockRate: string
  cents: string
  changeinpercent: string
  DailyLow: string
  DailyHigh: string
  AnnualLow: string
  AnnualHigh: string
  baseRate: string
}

type Props = {
  data: GetPageQuery
  bondsData?: BondsTableResponse
  stockGraph?: GraphStockResponse
  stockBanner?: SlimStockDto
  locale: string
  messages: InvestoreRelationsMessages
  page?: string
}
function InvestorsRelation({
  data,
  bondsData,
  stockGraph,
  stockBanner,
  messages,
  locale,
  page,
}: Props) {
  const { selfObject, parents } = getBreadCrumbs(data, locale)
  const components = {
    topBanner: TopBanner,
    titleTextLink: TitleWithButton,
    numbersComponent: InvestorsNumber,
    immediateReports: ImmediateReports,
    immediateReports1: Reports,
    singleArmBlock: SingleArm,
    activeArms: ActiveArms,
    shareBlock: ShareBlock,
  }
  const blocks = findProperty(data.page, "blocks")?.blocks || []
  const hasBanner = blocks?.some(
    (block: any) =>
      block.blockType === "topBanner" ||
      block.blockType === "HeaderWithHeroAndText"
  )
  return (
    <>
      <Container>
        <StyledContainer>
          <CustomBreadcrumbs current={selfObject} parents={parents} />
        </StyledContainer>
      </Container>

      {blocks?.map(
        // TO DO : HOW TO FIND BLOCK TYPE IN GENERATED GRAPHQL TYPES

        (block: any, index: number) => {
          let additionalProps = {}
          if (block.blockType === "numbersComponent") {
            additionalProps = { accent: false, accentColor: "white" }
          } else if (block.blockType === "shareBlock") {
            additionalProps = {
              bondsData: bondsData,
              stockGraph: stockGraph,
              messages: messages,
              page: page,
            }
          } else if (block.blockType === "contentText") {
            additionalProps = {
              hasBanner,
            }
          }
          return (
            <BlockRenderer
              key={index}
              blockType={block.blockType}
              blockData={block}
              components={components}
              props={additionalProps}
            >
              {block.blockType === "topBanner" && page === "corporate" && (
                <StockBanner
                  stockBanner={stockBanner}
                  change={messages.change}
                  lasttransaction={messages.lasttransaction}
                  StockRate={messages.StockRate}
                  cents={messages.cents}
                  baseRate={messages.baseRate}
                />
              )}
            </BlockRenderer>
          )
        }
      )}
    </>
  )
}

export default InvestorsRelation
