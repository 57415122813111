"use client"

import styled from "@emotion/styled"
import { Button, IconButton } from "@mui/material"
import { ArrowLeftIcon, ArrowRightIcon } from "@mui/x-date-pickers"
import Image from "next/image"
import Link from "next/link"
import {
  Container,
  CustomBreadcrumbs,
  Desktop,
  JobLocationIcon,
  Mobile,
  NewText,
} from "ui"
import { sharedColors } from "ui/theme/colors"
import {
  MatchedStatuses,
  ProjectPage,
  getNonUmbracoUrl,
  getUmbracoMediaUrl,
} from "utils"
import NewNumberGrid from "./NewNumberGrid"

const StyledGrid = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 32px;
  padding-bottom: 32px;

  ${(props) => props.theme.breakpoints.up("md")} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 0;

    gap: 80px;
  }
`

const StyledPaddingForMobile = styled.div`
  padding: 0 16px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 0;
  }
`

const StyledRelativeContainer = styled.div`
  position: relative;
  height: 570px;
  padding: 0 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 100%;
    padding: 0px;
    min-height: 600px;
  }
`

const StyledAbsoluteImage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  img {
    border-radius: 16px;
  }
`

const StyledBreadcrumbs = styled.div`
  padding-left: 16px;
  padding-right: 16px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding-left: 0px;
    padding-right: 0px;
  }
`

const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-bottom: 75px;
    padding-top: 75px;
    height: 100%;
    gap: 40px;
    justify-content: center;
  }
`

const Divider = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-left: 1px solid var(--Grey, #6f6f6f);
  background-color: "black";
  height: 20px;
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 24px;
  }
`
const StyledDetails = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 16px;
  }
`
const DescriptionStyled = styled.div`
  padding-top: 8px;
  padding-bottom: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 16px;
  }

  p {
    padding: 0;
    margin: 0;
  }
`
const StyledTextIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
`
const StyledIconButton = styled(IconButton)`
  margin-inline-start: -10px;
  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 0px;
  }
`
const ButtonsFlex = styled.div<{ contactAnchor?: string }>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${(props) => props.theme.breakpoints.down("md")} {
    ${(props) =>
      props.contactAnchor
        ? `
          padding-bottom: 32px;
    width: 100%;
    border: none;
    border-bottom: 1px solid;
    border-image: linear-gradient(
        to right,
        #ffffff 0%,
        #2768a3 50%,
        #ffffff 100%
      )
      1;
    `
        : ""}
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    align-items: center;
    flex-direction: row;
  }
`

const StyledMobileTopWrapp = styled.div`
  padding: 0px 16px 24px 16px;
`

const MediumText = styled.div``

const StyledButton = styled(Button)`
  text-decoration: none;
  height: fit-content;
  padding: 0;
  width: fit-content;
`

type Props = {
  locale: string
  statuses: MatchedStatuses
  city: string | undefined
  country: string | undefined
  designations: string[]
  projectData: ProjectPage
  virtualTourLinkName?: string
  marketing?: boolean
  contactAnchor?: string
}

function TopHeader({
  locale,
  city,
  country,
  designations,
  statuses,
  projectData,
  virtualTourLinkName,
  marketing,
  contactAnchor,
}: Props) {
  const {
    moreInfo,
    virtualTourLink,
    textHTML,
    url,
    projectTitle,
    heroBanner,
    priceText,
    priceCurrency,
    price,
    numbersNew,
  } = projectData
  const addressParts = [country, city, moreInfo]
  const validAddressParts = addressParts.filter(
    (part) => part !== undefined && part !== ""
  )
  const addressString = validAddressParts.join(", ")
  const scrollToContactUs = () => {
    const contactSection = document.getElementById("contact-us")
    if (contactSection) {
      const offset = 60 // Adjust this value as needed
      const elementPosition = contactSection.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.pageYOffset - offset
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      })
    }
  }
  return (
    <Container withoutPadding>
      <StyledBreadcrumbs>
        <CustomBreadcrumbs
          current={{
            title: projectTitle || "",
            url: getNonUmbracoUrl(url),
          }}
          parents={[
            {
              title: locale === "he" ? "ראשי" : "Homepage",
              url: locale === "he" ? "/" : "/en",
            },
          ]}
        />
      </StyledBreadcrumbs>
      <Mobile>
        <StyledMobileTopWrapp>
          <StyledTitle>
            <NewText
              desktopFontSize={40}
              desktopFontWeight="400"
              desktopLineHeight="44px"
              mobileFontSize={32}
              mobileFontWeight="400"
              mobileLineHeight={"36px"}
              component="h1"
              color={"black"}
            >
              {projectTitle}
            </NewText>
            <StyledDetails>
              <StyledTextIcon>
                <StyledIconButton
                  onClick={() => {
                    window.scrollTo({
                      behavior: "smooth",
                      top:
                        document
                          ?.getElementById("mapContainer")
                          ?.getBoundingClientRect().top || 0,
                    })
                  }}
                >
                  <JobLocationIcon color={sharedColors.black} />
                </StyledIconButton>
                <NewText
                  component={"p"}
                  desktopFontSize={20}
                  mobileFontSize={16}
                  desktopLineHeight={"24px"}
                  mobileLineHeight={"20px"}
                  color={sharedColors.black}
                >
                  {addressString}
                </NewText>
              </StyledTextIcon>
              <Divider />
              {designations.map((el) => (
                <NewText
                  component={"p"}
                  key={el}
                  desktopFontSize={20}
                  mobileFontSize={16}
                  desktopLineHeight={"24px"}
                  mobileLineHeight={"20px"}
                  color={sharedColors.black}
                >
                  {el}
                </NewText>
              ))}
              <Divider />
              {statuses.map((el) => (
                <NewText
                  component={"p"}
                  key={el.id}
                  desktopFontSize={20}
                  mobileFontSize={16}
                  desktopLineHeight={"24px"}
                  mobileLineHeight={"20px"}
                  color={sharedColors.black}
                >
                  {el.title}
                </NewText>
              ))}
            </StyledDetails>
          </StyledTitle>
        </StyledMobileTopWrapp>
      </Mobile>
      <StyledGrid>
        <StyledPaddingForMobile>
          <TopContent>
            <Desktop>
              <StyledTitle>
                <NewText
                  desktopFontSize={40}
                  desktopFontWeight="400"
                  desktopLineHeight="44px"
                  mobileFontSize={32}
                  mobileFontWeight="400"
                  mobileLineHeight={"36px"}
                  component="h1"
                  color={"black"}
                >
                  {projectTitle}
                </NewText>
                <StyledDetails>
                  <StyledTextIcon>
                    <StyledIconButton
                      onClick={() => {
                        window.scrollTo({
                          behavior: "smooth",
                          top:
                            document
                              ?.getElementById("mapContainer")
                              ?.getBoundingClientRect().top || 0,
                        })
                      }}
                    >
                      <JobLocationIcon color={sharedColors.black} />
                    </StyledIconButton>
                    <NewText
                      component={"p"}
                      desktopFontSize={20}
                      mobileFontSize={16}
                      desktopLineHeight={"24px"}
                      mobileLineHeight={"20px"}
                      color={sharedColors.black}
                    >
                      {addressString}
                    </NewText>
                  </StyledTextIcon>
                  <Divider />
                  {designations.map((el) => (
                    <NewText
                      component={"p"}
                      key={el}
                      desktopFontSize={20}
                      mobileFontSize={16}
                      desktopLineHeight={"24px"}
                      mobileLineHeight={"20px"}
                      color={sharedColors.black}
                    >
                      {el}
                    </NewText>
                  ))}
                  <Divider />
                  {statuses.map((el) => (
                    <NewText
                      component={"p"}
                      key={el.id}
                      desktopFontSize={20}
                      mobileFontSize={16}
                      desktopLineHeight={"24px"}
                      mobileLineHeight={"20px"}
                      color={sharedColors.black}
                    >
                      {el.title}
                    </NewText>
                  ))}
                </StyledDetails>
              </StyledTitle>
            </Desktop>
            {textHTML && !marketing && (
              <DescriptionStyled>
                <NewText
                  component={"div"}
                  desktopFontSize={24}
                  desktopLineHeight={1.45}
                  mobileFontSize={18}
                  mobileLineHeight={1.45}
                  color={sharedColors.black}
                  dangerouslySetInnerHTML={{
                    __html: textHTML || "",
                  }}
                />
              </DescriptionStyled>
            )}

            <MediumText>
              {marketing && (
                <>
                  <NewText
                    component={"span"}
                    desktopFontSize={20}
                    mobileFontSize={14}
                    desktopLineHeight={"24px"}
                    mobileLineHeight={"20px"}
                    color={sharedColors.black}
                  >
                    {priceText}
                  </NewText>
                  {price && (
                    <NewText
                      component={"h3"}
                      desktopFontSize={32}
                      mobileFontSize={30}
                      color={sharedColors.black}
                      mobileLineHeight={"28px"}
                      desktopLineHeight={"36px"}
                    >
                      {`${price} `}
                      <NewText
                        component={"span"}
                        desktopFontSize={22}
                        mobileFontSize={22}
                        color={sharedColors.black}
                      >
                        {priceCurrency}
                      </NewText>
                    </NewText>
                  )}
                  <DescriptionStyled>
                    <NewText
                      component={"div"}
                      desktopFontSize={24}
                      desktopLineHeight={1.45}
                      mobileFontSize={18}
                      mobileLineHeight={1.45}
                      color={sharedColors.black}
                      dangerouslySetInnerHTML={{
                        __html: textHTML || "",
                      }}
                    />
                  </DescriptionStyled>
                </>
              )}
              <Desktop>
                <ButtonsFlex>
                  <Link target="_blank" href={virtualTourLink || ""}>
                    {virtualTourLinkName?.length && (
                      <Button
                        variant="outlined"
                        size="large"
                        style={{ fontSize: 20 }}
                      >
                        {virtualTourLinkName}
                      </Button>
                    )}
                  </Link>
                  {contactAnchor && (
                    <StyledButton variant="text" onClick={scrollToContactUs}>
                      <NewText
                        component={"span"}
                        color="black"
                        desktopFontSize={20}
                        desktopLineHeight={"normal"}
                        desktopFontWeight={600}
                      >
                        {contactAnchor}
                      </NewText>
                      {locale === "he" ? <ArrowLeftIcon /> : <ArrowRightIcon />}
                    </StyledButton>
                  )}
                </ButtonsFlex>
              </Desktop>
            </MediumText>

            <NewNumberGrid data={numbersNew} />

            <Mobile>
              <ButtonsFlex contactAnchor={contactAnchor}>
                <Link target="_blank" href={virtualTourLink || ""}>
                  {virtualTourLinkName?.length && (
                    <Button
                      variant="outlined"
                      size="large"
                      style={{ fontSize: 20 }}
                    >
                      {virtualTourLinkName}
                    </Button>
                  )}
                </Link>
                {contactAnchor && (
                  <StyledButton variant="text" onClick={scrollToContactUs}>
                    <NewText
                      component={"span"}
                      color="black"
                      desktopFontSize={20}
                      desktopLineHeight={"normal"}
                      desktopFontWeight={600}
                    >
                      {contactAnchor}
                    </NewText>
                    <ArrowLeftIcon />
                  </StyledButton>
                )}
              </ButtonsFlex>
            </Mobile>
          </TopContent>
        </StyledPaddingForMobile>
        <StyledRelativeContainer>
          <StyledAbsoluteImage>
            <Desktop>
              <Image
                style={{ objectFit: "cover", objectPosition: "right" }}
                src={getUmbracoMediaUrl(heroBanner?.desktopImage)}
                fill
                alt="background"
              />
            </Desktop>
            <Mobile>
              <Image
                style={{ objectFit: "cover", objectPosition: "bottom" }}
                src={getUmbracoMediaUrl(
                  heroBanner?.mobileImage || heroBanner?.desktopImage
                )}
                fill
                alt="background"
              />
            </Mobile>
          </StyledAbsoluteImage>
        </StyledRelativeContainer>
      </StyledGrid>
    </Container>
  )
}

export default TopHeader
