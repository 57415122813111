import styled from "@emotion/styled"
import { Button, IconButton } from "@mui/material"
import { LobbyAvailableFilters } from "api"
import { Dispatch, SetStateAction, useState } from "react"
import { FilterIcon, Text } from "ui"
import { sharedColors } from "ui/theme/colors"
import { jobFilter } from "utils"
import { CareerLobbyMessages } from "../Careers"
import FiltersModal from "./FiltersModal"
import { FilterState } from "./Jobs"

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: row;
    align-items: start;
    gap: 10px;
    justify-content: space-between;
  }
`

const StyledGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  ${(props) => props.theme.breakpoints.up("lg")} {
    gap: 16px;
  }
`

const MoreDesignations = styled.div`
  width: max-content;
  margin-right: auto;
`

const StyledButton = styled(Button)<{ active: boolean }>`
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  font-size: 18px;
  box-sizing: border-box;
  font-weight: ${(props) => (props.active ? "600" : "400")};
  width: max-content;
  border: ${(props) =>
    props.active
      ? "1px solid transparent"
      : "1px solid rgba(111, 111, 111, 1)"};
  background-color: ${(props) =>
    props.active ? sharedColors.oldBrandBlue : "transparent"};
  color: ${(props) => (props.active ? "white" : "black")};
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 18px;
  }
  :hover {
    font-weight: 600;
    border-width: 1px;
  }
  @media (hover: none) {
    &:hover {
      font-weight: ${({ active }) => !active && "unset  "};
      background-color: ${({ active }) => !active && "transparent"};
      color: ${({ active }) => !active && "black"};
      border-color: ${({ active }) => !active && "rgba(111, 111, 111, 1)"};
      box-shadow: ${({ active }) => !active && "none"};
    }
  }
`

const FilterContainer = styled.div`
  display: flex;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  gap: 8px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-right: 0;
    gap: 4px;
  }
`
const StyledIconButton = styled(IconButton)`
  position: relative;
  margin: 0;
  padding: 0;
  :hover {
    background-color: transparent;
  }
  :active {
    background-color: transparent;
  }
`

const CountCont = styled.div`
  position: absolute;
  text-align: center;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${sharedColors.oldBrandBlue};
  border-radius: 200px;
  color: white;
  font-size: 16px;
`

function countFilters(state: FilterState): number {
  // Specify the keys you want to count the lengths of
  const keysToCount: (keyof FilterState)[] = [
    "companyIds",
    "areaIds",
    "jobScopeIds",
  ]

  const totalCount = keysToCount.reduce((sum, key) => {
    if (Array.isArray(state[key])) {
      return sum + state[key].length
    }
    return sum
  }, 0)

  return totalCount
}

const emptyState = {
  areaIds: [],
  companyDivisions: [],
  companyIds: [],
  jobScopeIds: [],
  orderIds: [],
  professionIds: [],
}

type Props = {
  filters: LobbyAvailableFilters
  state: FilterState
  setState: Dispatch<SetStateAction<FilterState>>
  messages: CareerLobbyMessages
  companyDivisionId: number[]
}
function FiltersComponent({
  filters,
  state,
  setState,
  messages,
  companyDivisionId,
}: Props) {
  const [interimState, setInterimState] = useState<FilterState>(state)
  const isAnySelected = Object.values(state).some((value) => {
    return Array.isArray(value) && value.length > 0
  })
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
    jobFilter("מסננים", "מסננים", "קריירה פילטר")
  }
  const handleClose = () => setOpen(false)
  const selectOne = (selectedId: number) => {
    setState((prevState) => {
      const isSelected = prevState.professionIds.includes(selectedId)
      if (isSelected) {
        return {
          ...prevState,
          professionIds: prevState.professionIds.filter(
            (id: number) => id !== selectedId
          ),
        }
      }
      return {
        ...prevState,
        professionIds: [...prevState.professionIds, selectedId],
      }
    })
  }
  const selectAll = () => {
    setInterimState(emptyState)
    setState(emptyState)
  }
  const clearAll = () => {
    setInterimState(emptyState)
    setState((prev) => ({
      ...emptyState,
      professionIds: prev.professionIds,
    }))
  }
  const [showAll, setShowAll] = useState(false)
  const count = countFilters(state)
  const displayValues = showAll
    ? filters.avialableProfessions
    : filters.avialableProfessions.slice(0, 5)
  return (
    <>
      <StyledContainer>
        <StyledGrid>
          <StyledButton
            variant="outlined"
            active={!isAnySelected}
            onClick={selectAll}
          >
            כל המשרות
          </StyledButton>

          {displayValues.map((el) => (
            <StyledButton
              variant="outlined"
              active={
                !!state.professionIds.find((filt: number) => filt === el.id)
              }
              key={el.id}
              onClick={() => {
                selectOne(el.id)
                jobFilter(el.name, "profession name", "קריירה פילטר")
              }}
            >
              {el.name}
            </StyledButton>
          ))}
          {showAll ? null : (
            <MoreDesignations>
              <StyledButton
                variant="outlined"
                active={false}
                onClick={() => setShowAll(true)}
              >
                + הצגת כל המשרות
              </StyledButton>
            </MoreDesignations>
          )}
        </StyledGrid>

        <FilterContainer>
          <StyledIconButton onClick={handleOpen}>
            {count > 0 ? (
              <CountCont>
                <span>{count}</span>
              </CountCont>
            ) : null}
            <FilterIcon />
          </StyledIconButton>
          <Text variant="MediumText">מסננים</Text>
        </FilterContainer>
        <FiltersModal
          interimState={interimState}
          setInterimState={setInterimState}
          setState={setState}
          handleClose={handleClose}
          clearAll={clearAll}
          open={open}
          filters={filters}
          messages={messages}
          companyDivisionId={companyDivisionId}
        />
      </StyledContainer>
    </>
  )
}

export default FiltersComponent
