"use client"

import styled from "@emotion/styled"
import { Button } from "@mui/material"
import Link from "next/link"
import { Dispatch, SetStateAction } from "react"
import { CircleXIcon, NewText } from "ui"
import { sharedColors } from "ui/theme/colors"
import { contactButtonClick } from "utils"
import { ProjectsMessages } from "./Projects"

const StyledAbsolute = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const HiddenButtons = styled.div`
  position: absolute;
  bottom: 64px;
  left: 50%;
  transform: translateX(-50%);
`
const StickyOutside = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: white;
  border-radius: 200px;
  height: 74px;
  width: 180px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
  :hover {
    border: 1px solid black;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 196px;
  }
`
const RelativeDiv = styled.div`
  position: relative;
`
const StyledButton = styled(Button)`
  background-color: white;
  margin-bottom: 16px;
  min-height: 53px;
  width: 164px;
  padding-left: 0;
  padding-right: 0;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 180px;
  }
`

const StyledStickButton = styled(Button)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 58px;
  color: white;
  white-space: nowrap;
  background-color: ${sharedColors.oldBrandBlue};
  border: none;
  :hover,
  :active {
    border: none;
    box-shadow: none;
  }
`

const StyledCloseText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
type Props = {
  messages: ProjectsMessages
  setIsBackdropVisible: Dispatch<SetStateAction<boolean>>
  open: boolean
  setIsDropDownOpen: Dispatch<SetStateAction<boolean>>
  setIsFormVisible: Dispatch<SetStateAction<boolean>>
  phone?: string
  miniSite?: string
  virtualTourLink?: string
  projectType?: string
  virtualTourLinkName?: string
}

function StickyCTA({
  setIsBackdropVisible,
  setIsFormVisible,
  setIsDropDownOpen,
  open,
  miniSite,
  phone,
  virtualTourLink,
  messages,
  projectType,
  virtualTourLinkName,
}: Props) {
  const isLegacy = projectType === "מורשת"
  const isPlanning = projectType === "תכנון ובנייה"

  const handleClick = () => {
    contactButtonClick(
      "יצירת קשר לקבלת מחיר מנציג/ה",
      !open ? "open" : "close",
      "כפתור פופ אפ יצירת קשר"
    )
    setIsBackdropVisible((prev) => !prev)
    setIsDropDownOpen((prev) => !prev)
  }
  const handleFormClick = () => {
    setIsDropDownOpen(false)
    setIsFormVisible(true)
    contactButtonClick(
      "יצירת קשר לקבלת מחיר מנציג/ה",
      "השארת פרטים",
      "כפתור פופ אפ יצירת קשר"
    )
  }

  return (
    <>
      {isLegacy ? null : (
        <StyledAbsolute>
          <RelativeDiv>
            {open ? (
              <>
                <HiddenButtons>
                  <StyledButton
                    variant="outlined"
                    size="large"
                    onClick={handleFormClick}
                  >
                    {messages.leaveDetails}
                  </StyledButton>
                  {isPlanning ? null : (
                    <>
                      {phone ? (
                        <a href={phone}>
                          <StyledButton
                            variant="outlined"
                            size="large"
                            onClick={() =>
                              contactButtonClick(
                                "יצירת קשר לקבלת מחיר מנציג/ה",
                                "שיחה עם מוקד מכירות",
                                "כפתור פופ אפ יצירת קשר"
                              )
                            }
                          >
                            {messages.salesCenter}
                          </StyledButton>
                        </a>
                      ) : null}
                      {miniSite ? (
                        <Link href={miniSite}>
                          <StyledButton
                            variant="outlined"
                            size="large"
                            onClick={() =>
                              contactButtonClick(
                                "יצירת קשר לקבלת מחיר מנציג/ה",
                                "לאתר מכירות",
                                "כפתור פופ אפ יצירת קשר"
                              )
                            }
                          >
                            {messages.sales}
                          </StyledButton>
                        </Link>
                      ) : null}
                      {virtualTourLink ? (
                        <Link href={virtualTourLink}>
                          <StyledButton
                            variant="outlined"
                            size="large"
                            onClick={() =>
                              contactButtonClick(
                                "יצירת קשר לקבלת מחיר מנציג/ה",
                                "לסיור וירטואלי",
                                "כפתור פופ אפ יצירת קשר"
                              )
                            }
                          >
                            {virtualTourLinkName}
                          </StyledButton>
                        </Link>
                      ) : null}
                    </>
                  )}
                </HiddenButtons>
              </>
            ) : null}
            <StickyOutside>
              <StyledStickButton variant="outlined" onClick={handleClick}>
                {open ? (
                  <StyledCloseText>
                    <CircleXIcon />
                    <NewText
                      component={"h3"}
                      desktopFontSize={20}
                      inheritColor={true}
                      desktopFontWeight={600}
                    >
                      {messages.close}
                    </NewText>
                  </StyledCloseText>
                ) : (
                  <>
                    <NewText
                      component={"h4"}
                      desktopFontSize={20}
                      desktopFontWeight={600}
                      inheritColor={true}
                    >
                      {messages.contactUs}
                    </NewText>
                  </>
                )}
              </StyledStickButton>
            </StickyOutside>
          </RelativeDiv>
        </StyledAbsolute>
      )}
    </>
  )
}

export default StickyCTA
