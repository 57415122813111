"use client"

import { GetPageQuery } from "apollo"
import { Suspense, useRef } from "react"
import { BlockRenderer, Container, CustomBreadcrumbs, TopBanner } from "ui"
import {
  extractProperty,
  findProperty,
  getBreadCrumbs,
  type ProjectFilters,
} from "utils"
import { AllProjects } from "utils/projects"
import ContentText from "./ContentText"
import ProjectsLayout from "./ProjectsLayout"

type Props = {
  pageData: GetPageQuery
  projectsData: AllProjects
  filters: ProjectFilters
  relativeProjectsPath?: boolean
  locale: string
  projectsForPage?: any
  page?: string
  messages: {
    showAll: string
    all: string
    showResults: string
    noProjectsFound: string
    toProject: string
    filters: string
    moreCategory: string
    allProjects: string
  }
  hideFilters?: boolean
}

const components = {
  topBanner: TopBanner,
  contentText: ContentText,
}
function ProjectLobby({
  pageData,
  projectsData,
  filters,
  messages,
  relativeProjectsPath,
  projectsForPage,
  locale,
  page,
  hideFilters,
}: Props) {
  // const [searchText, setSearchText] = useState<string | null>(null)
  const { selfObject, parents } = getBreadCrumbs(pageData, locale)
  const resultRef = useRef<null | HTMLDivElement>(null)

  // const isDesktop = useIsDesktop()
  // const yOffest = -120

  // const handleSearchClick = () => {
  //   if (resultRef && resultRef.current) {
  //     if (isDesktop) {
  //       window.scrollTo({
  //         behavior: "smooth",
  //         top: resultRef?.current?.getBoundingClientRect().top + yOffest,
  //       })
  //     } else
  //       resultRef.current.scrollIntoView({ block: "start", behavior: "smooth" })
  //   }
  // }

  const blocks = findProperty(pageData.page, "blocks")?.blocks || []
  const hasBanner = blocks?.some(
    (block: any) =>
      block.blockType === "topBanner" ||
      block.blockType === "HeaderWithHeroAndText"
  )
  const projectButton = blocks?.find(
    (el: any) => el.blockType === "projectButton"
  )
  const projectButtonUrl = extractProperty(projectButton, "buttonUrl")?.[0] as
    | undefined
    | { url: string; name: string }
  return (
    <>
      <Container>
        <CustomBreadcrumbs current={selfObject} parents={parents} />
      </Container>
      {blocks?.map((block: any, index: number) => {
        let additionalProps = {}
        if (block.blockType === "contentText") {
          additionalProps = {
            hasBanner: hasBanner,
            videoHeight: "500px", //searchText
            projectButtonUrl,
          }
        } else {
          additionalProps = {
            videoHeight: "500px", //searchText
          }
        }
        return (
          <BlockRenderer
            key={index}
            blockType={block.blockType}
            blockData={block}
            components={components}
            props={additionalProps}
          />
        )
      })}
      {projectsData.length > 0 && (
        <Suspense>
          <ProjectsLayout
            projects={projectsData}
            filters={filters}
            relativeProjectsPath={relativeProjectsPath}
            messages={messages}
            // searchText={searchText}
            locale={locale}
            resultRef={resultRef}
            projectsForPage={projectsForPage}
            page={page}
            hideFilters={hideFilters}
          />
        </Suspense>
      )}
    </>
  )
}

export default ProjectLobby
