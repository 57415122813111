import styled from "@emotion/styled"
import { Container, NewText } from "ui"
import { sharedColors as colors } from "ui/theme/colors"
import { extractProperty } from "utils"
import { InvestoreRelationsMessages } from "../InvestorsRelation/InvestorsRelation"
import Stock from "../InvestorsRelation/Stocks"
import StockTable from "./StockTable"

const StyledPageDiv = styled.div`
  padding-top: 32px;
  padding-bottom: 32px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`
const StyledInnerWrapper = styled.div`
  padding-top: 32px;
  display: flex;
  justify-content: flex-start;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 40px;
    justify-content: center;
  }
`

const StyledChartDiv = styled.div`
  margin-top: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 40px;
  }
`

const StyledTableDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`
type Props = {
  data: any
  stockGraph: any
  messages: InvestoreRelationsMessages
  children: React.ReactNode
}

function StockInvestorsMain({ data, stockGraph, messages, children }: Props) {
  const mainTitle = extractProperty(data, "mainTitle")

  return (
    <Container>
      <StyledPageDiv>
        {children}
        <StyledInnerWrapper>
          <NewText
            component={"h1"}
            desktopFontSize={40}
            desktopLineHeight={"44px"}
            desktopFontWeight={400}
            mobileFontSize={32}
            mobileLineHeight={"36px"}
            color={colors.black}
          >
            {mainTitle}
          </NewText>
        </StyledInnerWrapper>
        <StyledChartDiv>
          <Stock data={stockGraph} type="inner" messages={messages} />
        </StyledChartDiv>
        <StyledTableDiv>
          <StockTable data={stockGraph?.stockDto} messages={messages} />
        </StyledTableDiv>
      </StyledPageDiv>
    </Container>
  )
}

export default StockInvestorsMain
