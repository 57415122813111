"use client"

import { GetPageQuery } from "apollo"
import { useTranslations } from "next-intl"
import { BlockRenderer, Container, CustomBreadcrumbs, TopBanner } from "ui"
import { getBreadCrumbs } from "utils"
import { findProperty } from "utils/findProperty"
import ContactContent from "./ContactContent"
import ContactForm from "./ContactForm"

type Props = {
  data: GetPageQuery
  locale: string
  companyId: string
}

function Contact({ data, locale, companyId }: Props) {
  const { selfObject, parents } = getBreadCrumbs(data, locale)
  const components = {
    topBanner: TopBanner,
    contactHeader: ContactContent,
    contactHeader1: ContactForm,
  }

  const t = useTranslations("ProjectsPage")
  const messages = {
    contactUs: t("contactUs"),
    toProject: t("toProject"),
    priceOffer: t("priceOffer"),
    leaveDetails: t("leaveDetails"),
    close: t("close"),
    virtualTour: t("virtualTour"),
    sales: t("sales"),
    salesCenter: t("salesCenter"),
    forYou: t("forYou"),
    required: t("required"),
    subject: t("subject"),
    fullName: t("fullName"),
    telephone: t("telephone"),
    email: t("email"),
    messageOptional: t("messageOptional"),
    consentMessage: t("consentMessage"),
    privacy: t("privacy"),
    terms: t("terms"),
    send: t("send"),
    groups: t("groups"),
    collaborators: t("collaborators"),
    entr: t("entr"),
    sucessTitle: t("sucessTitle"),
    sucessText: t("sucessText"),
    contactSucessTitle: t("contactSucessTitle"),
    contactSucessText: t("contactSucessText"),
  }
  const blocks = findProperty(data.page, "blocks")?.blocks || []
  const hasBanner = blocks?.some(
    (block: any) =>
      block.blockType === "topBanner" ||
      block.blockType === "HeaderWithHeroAndText"
  )

  return (
    <>
      <Container>
        <CustomBreadcrumbs current={selfObject} parents={parents} />
      </Container>
      {blocks?.map((block: any, index: number) => {
        let additionalProps = {}
        if (block.blockType === "contentText") {
          additionalProps = {
            hasBanner,
            messages,
            companyId,
            accentColor:
              block.blockType === "numbersComponent1" ? "black" : "unset",
            videoHeight: "500px",
          }
        } else {
          additionalProps = {
            messages,
            companyId,
            accentColor:
              block.blockType === "numbersComponent1" ? "black" : "unset",
            videoHeight: "500px",
          }
        }
        return (
          <BlockRenderer
            key={index}
            blockType={block.blockType}
            blockData={block}
            components={components}
            props={additionalProps}
          />
        )
      })}
    </>
  )
}

export default Contact
