"use client"

import { GetPageQuery } from "apollo"
import { BlockRenderer, Container, CustomBreadcrumbs, TopBanner } from "ui"
import { getBreadCrumbs } from "utils"
import { findProperty } from "utils/findProperty"
import FaqMain from "./FaqMain"
import TitleContent from "./TitleContent"

type Props = {
  data: GetPageQuery
  locale: string
}

function Faq({ data, locale }: Props) {
  const { selfObject, parents } = getBreadCrumbs(data, locale)
  const components = {
    topBanner: TopBanner,
    contentText: TitleContent,
    accordionFaqMain: FaqMain,
  }
  const blocks = findProperty(data.page, "blocks")?.blocks || []
  const hasBanner = blocks?.some(
    (block: any) =>
      block.blockType === "topBanner" ||
      block.blockType === "HeaderWithHeroAndText"
  )
  return (
    <>
      <Container>
        <CustomBreadcrumbs current={selfObject} parents={parents} />
      </Container>
      {findProperty(data.page, "blocks")?.blocks?.map(
        (block: any, index: number) => {
          let additionalProps = {}
          if (block.blockType === "contentText") {
            additionalProps = {
              hasBanner,
            }
          } else if (block.blockType === "numbersComponent1") {
            additionalProps = {
              accentColor: "black",
            }
          }
          return (
            <BlockRenderer
              key={index}
              blockType={block.blockType}
              blockData={block}
              components={components}
              props={additionalProps}
            />
          )
        }
      )}
    </>
  )
}

export default Faq
